import React from 'react'
import { AuthButton, FormContainer, LoginHeader, FormMain, LoginFooter, FormMainHeader, InputFieldItem } from 'pages/auth/auth.styled'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify' 
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { login, reset, loginOrganization } from '../../features/auth/authSlice'
import { setForgotPasswordUserType, setViewForgotPassword, setViewEmailConfirmModal } from '../../features/auth/authSlice' 
import SpinnerSmall from '../../components/SpinnerSmall'
import useKeypress from '../../hooks/useKeypress' 

export default function LoginForm() {

    const dispatch = useDispatch()

    return (
        <FormContainer>
            <LoginHeader>
                <img
                    style={{ width: "100px", height: "40px" }} 
                    src={require("../../prayselyLogo.png")} alt="logo" />
            </LoginHeader>
            <FormMain>  
                <FormMainHeader>
                    <h1>Welcome Back!</h1>
                    <p>Sign in to your account</p>
                </FormMainHeader>

                <InputFieldItem>
                    <input type="text" placeholder="Email" /> 
                </InputFieldItem>
                <InputFieldItem>
                    <input type="password" placeholder="Password" />
                </InputFieldItem>

                <AuthButton

                    >
                        Login
                </AuthButton>

            </FormMain>
            <LoginFooter>
                <span> &copy; 2025 Praysely, Inc </span>
            </LoginFooter>
        </FormContainer>
    )
}
