import { useEffect, useState } from "react"
import styled from 'styled-components' 
import { closeCreateAncmtModal, chooseAncmntImage, createAnnouncement } from 'features/anouncements/announcementSlice';
import { useSelector, useDispatch } from "react-redux"
import placeholder from "../../images/directoryImages/default/addImage.png"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CampaignIcon from '@mui/icons-material/Campaign';
import { toast } from "react-toastify";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BottomWrap, SubmitButton } from "components/giving/giving.styled";
import ModalCloseButton from "components/buttons/ModalCloseButton";
import { language } from "app/language"; 
import SpinnerSmall from "components/SpinnerSmall";

export default function CreateAncmtModal() {

    const [sendTextAsEmail, setSendTextAsEmail] = useState(false)
    const dispatch = useDispatch()
    const [announcementText, setAnnouncementText] = useState('')
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const {  isAnnouncementCreatedLoading, isAnnouncementCreatedSuccess } = useSelector((state) => state.announcement )
    const { user } = useSelector((state) => state.auth)

    const handlePostTextChange = (e) => {
        setAnnouncementText(e.target.value)
    }

    const [{alt, src, edited, fileSrc}, setImg] = useState({
        src: placeholder,
        alt: 'Upload an Image',
        edited: false,
    });

    const handleImg = async (e) => {
        if(e.target.files[0]) {
            let fileName = e.target.files[0].name
            let fileType =  fileName.slice(fileName.lastIndexOf('.')+1).toLowerCase()

            if(fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png'){
                return toast.error('Select a JPG or PNG files only')
            }

            dispatch(chooseAncmntImage(e.target.files[0]))
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name,
                fileSrc: e.target.files[0],
                edited: true
            });    
        }
    }

    const handleSendTextAsEmail = (value) => { 
        if(value === true){
            toast.info('Email will be sent to all members with the text content of this anouncement', {
                autoClose: 3200,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false, 
                progress: undefined,
            })
        }else{
            toast.error('Email will not be sent to members', {
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false, 
                progress: undefined,
            })
        } 
        setSendTextAsEmail(value)
    }

    const removeImagePreview = () => {
        dispatch(chooseAncmntImage(null))
        setImg({
            src: placeholder,
            fileSrc: '',
            alt: 'Upload an Image',
            edited: false
        });    
    }

    const handleSubmit = async () => {
        const formData = new FormData();

        if(announcementText.length < 3 ){
            return toast.error('No announcement text to post');
        }
        if(fileSrc){
            console.log(fileSrc)
            formData.append("file", fileSrc);
        }
        formData.append("announcementText", announcementText);
        formData.append("sendTextAsEmail", sendTextAsEmail);
        formData.append("churchId", profileChurch._id);
        dispatch(createAnnouncement(formData))
    }

    useEffect(() => {
        if(isAnnouncementCreatedSuccess){ 
            toast.success('Announcement created!')
            dispatch(closeCreateAncmtModal()) 
        }
    }, [isAnnouncementCreatedSuccess, dispatch])

  return (
    <CreateAncmtModalCont>
        <CAModalContainer> 
            <ModalTopHeading> 
                <CampaignIcon style={{  fontSize: '20px',   marginRight: '10px'}} />  
                <AnnouncementModalHeading>
                    {language.createAnnouncement[user.preferredLanguage]}
                </AnnouncementModalHeading>
            </ModalTopHeading>

            <ModalCloseButton 
                handler={() => dispatch(closeCreateAncmtModal())} 
                size='35px'
                top='10px'
                right='10px'
                />

            <TA 
                value={announcementText} 
                onChange={handlePostTextChange} 
                placeholder={language.startAnnouncementHere[user.preferredLanguage]} 
                />

            { edited && (
                <CPImage>
                    <img style={{ width: '95%', height: 'auto'}} src={src} alt={alt} />
                    <RemoveImgBtn  onClick={removeImagePreview} > 
                        <DeleteOutlineIcon /> 
                    </RemoveImgBtn>
                </CPImage>
            )}

            <BottomSection> 
                <ATPEleLabel> 
                    <AddPhotoAlternateIcon  /> 
                    <span style={{marginLeft: '10px'}}>  
                        { edited ? (language.replace[user.preferredLanguage]):(language.add[user.preferredLanguage])} 
                        { ' ' + language.image[user.preferredLanguage]}
                    </span>
                </ATPEleLabel>
                <input
                    hidden  
                    id="imageUpload"  
                    type="file" 
                    accept=".png, .jpg, .jpeg" 
                    name="file" 
                    onChange={handleImg}
                    />
                     
                <SendEmailToMembers
                    selected={sendTextAsEmail}
                    onClick={() => handleSendTextAsEmail(!sendTextAsEmail)}
                    >
                    {
                        sendTextAsEmail && (
                            <span style={{marginRight: '10px'}}>
                                <CheckCircleIcon style={{color: 'green'}} />
                            </span>
                        )
                    }
                    {language.sendEmailToMembers[user.preferredLanguage]}
                </SendEmailToMembers>
            </BottomSection>

            <BottomWrap>
                <SubmitButton
                    width='200px'
                    disabled={isAnnouncementCreatedLoading}  
                    onClick={handleSubmit} 
                    >
                    { isAnnouncementCreatedLoading ? 
                        (
                            <SpinnerSmall />
                        )
                        :
                        (
                            language.createAnnouncement[user.preferredLanguage]
                        ) 
                    } 
                </SubmitButton>
            </BottomWrap>
        </CAModalContainer>
    </CreateAncmtModalCont>
  )
}

const BottomSection = styled.div`   
    display: flex;
    flex-direction: row ;
    align-items: center;
    width: 100%;
    justify-content: space-between; 

    @media (max-width: 768px) {
        padding: 0px 10px;
    }
`


const SendEmailToMembers = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.selected ? ('green'):('white') };
    border-radius: 10px; 
    font-size: 14px;
    color: ${props => props.selected ? ('var(--lightThemeFontColor)'):('grey') };
    width: fit-content;
    cursor: pointer;
    /* background-color: #efefef; */
    background-color: ${props => props.selected ? ('#efefef'):('none') };
    border: 1px solid ${props => props.selected ? ('none'):('#d8d8d8') };

    &:hover{
        background-color: #e7e7e7;
        border-radius: 10px;
    }

`

const CreateAncmtModalCont = styled.div`
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);


`

const CAModalContainer = styled.div`
    background-color: white;
    width: 550px;
    min-height: 400px;
    height: fit-content;
    padding: 20px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    position: relative;
    @media (max-width: 768px) {
        width: 100%;
        border-radius: 2px;
    }
    @media (max-width: 568px) {
        width: 95%;
        border-radius: 2px;
    }

`

const TA = styled.textarea`
    resize: none;
    width: 100%;
    margin-top: 0px;
    height: 140px;
    border: none;
    outline: none; 
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: rgb(128, 128, 128);
    font-weight: 100;
    border:  ${props => props.isError ? ('1px solid red'):('none') };
    background-color: aliceblue;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;

    /* style placeholder */
    &::placeholder {
        color: rgb(128, 128, 128);
        font-size: 14px;
        font-weight: 100;
    }

`

const CPImage = styled.div`
    height: 200px;
    overflow-y: scroll;
    position: relative;
`

const RemoveImgBtn = styled.div`
    position: absolute;
    top: 5px;
    left: 15px;
    z-index: 10;
    padding: 10px;
    background-color: red;
    cursor: pointer;
    opacity: 0.5;
    color: white;
`

const ATPEleLabel = styled.label.attrs(() => ({
    htmlFor: 'imageUpload'
  }))`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px; 
    width: fit-content;
    color: var(--lightThemeFontColor)   ;
    cursor: pointer;
    background-color: #e7e7e7;
    border-radius: 10px;
    
    &:hover{
        background-color: #dcdcdc;
    }

    @media (max-width: 768px) {
        span{
            font-size: 13px;
        }
    }

`

 

const ModalTopHeading = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: row;
    align-items: flex-start;  
    justify-content: flex-start; 
    color: var(--lightThemeFontColor);   
`
 

const AnnouncementModalHeading = styled.div`
  font-size: 14px; 

`
