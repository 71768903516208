import { ModalContainerMain } from 'components/modal/modal.styled'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { seeWhoIsPraying, setViewWhoIsPrayingModal, resetViewPrayingState } from 'features/posts/postSlice'
import { useDispatch, useSelector } from 'react-redux'
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import Loader from 'loaders/Loader'
import { baseUrl } from 'features/constants'

export default function WhoIsPrayingModal() {
    const dispatch = useDispatch()

    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { usersPraying, seeUsersPrayingLoading, seeUsersPrayingSuccess, selectedPostToViewPrayers } = useSelector((state) => state.posts)
    const closeModal = () => {
        dispatch(resetViewPrayingState())
        // reset the usersPraying stat
        dispatch(setViewWhoIsPrayingModal(false))
    }

    useEffect(() => { 
        if(selectedPostToViewPrayers.usersPraying.length > 0){
            dispatch(seeWhoIsPraying({
                postId: selectedPostToViewPrayers.id,
                churchId: profileChurch._id,
                userList: JSON.stringify(selectedPostToViewPrayers.usersPraying)
            }))
        }
 
    }, [selectedPostToViewPrayers])

    return (
            <UserPrayingModalWrap>
                <ModalCloseButton 
                    top='20px'
                    right='20px'
                    size='36px'
                    handler={closeModal}    
                    />

                <h1>
                    {
                        seeUsersPrayingSuccess && usersPraying.length > 0 &&
                        (
                            `${usersPraying.length} ${usersPraying.length > 1 ?('People are'):('Person is')} praying for this request`
                        )
                    }
                </h1>

                <UsersPrayingFeed>
                    {seeUsersPrayingLoading && <Loader /> }



                    {seeUsersPrayingSuccess && usersPraying.map( (user, index) => {
                        return (
                            <PersonPraying key={index}> 
                                <img
                                    src={`https://api.praysely.com/static/img/profile/${user.profileImg.imagePath2}`}
                                    alt={user.fullName}
                                    style={{width: '35px', height: '35px', borderRadius: '50%', marginRight: '10px'}}
                                />
                                <p>{user.fullName}</p>
                            </PersonPraying>
                        )
                    })}
                </UsersPrayingFeed>                
            </UserPrayingModalWrap> 
    )
}

const UserPrayingModalWrap = styled.div`

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 400px;
    overflow-y: scroll;
    padding: 20px; 
    background-color: white;

    h1{
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        color: var(--lightThemeFontColor)  ; 
        width: 100%;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;
    }

    @media (max-width: 768px) {
        width: 95vw;
    
        h1{
            font-size: 12px;

        }
        
    }

`

const PersonPraying = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%; 
    padding: 6px; 
    margin-bottom: 3px;
    p{
        font-size: 14px;
        color: var(--lightThemeFontColor);
    }
    @media (max-width: 768px) {
  
        p{
            font-size: 12px;
        }
    }
`

const UsersPrayingFeed = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 20px; 

`