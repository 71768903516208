import styled from "styled-components"

export const PostTextWrap = styled.div` 
    display: flex;
    flex-direction: column;
    color: var(--lightThemeFontColor);
    width: 100%; 
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ${props => props.hasImage ? '10px': '0px'};


    @media (max-width: 768px) {
        padding-left: 0px;
        padding-top: ${props => props.hasImage ? '10px': '0px'};
    }
`

export const PostSubText = styled.div`
    display: flex;
    margin-top: 5px;

`
export const SeeMoreBtn = styled.button`
    color: var(--lightThemeFontColor);
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 15px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`
export const SeeMore = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end; 
`




export const MobilePageTitle = styled.h1`
    display: none;

    @media (max-width: 768px) {

        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: fit-content;
        color: var(--lightThemeFontColor);
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        /* margin-top: 15px; */
        /* margin-bottom: 15px; */
    }
`

export const NoItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    font-size: 20px;
    color: #ccc;

    svg{
        font-size: 50px;
        color: #ccc;
        margin-top: 20px;
        margin-bottom: 10px;
    }

`