import React, { useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import CreateButton from 'components/buttons/CreateButton'; 
import { IoFolderOpenOutline } from "react-icons/io5";
import { deleteFolder, renameFolder, setViewFileModal, resetUploadState, setFolderToAddFile, setSelectedFolder, setWidths } from 'features/files/fileSlice'
import { Modal } from '@mui/material' 
import FileItem from './fileItem/FileItem';
import { VscNewFile } from "react-icons/vsc"; 
import { GoCheck } from "react-icons/go";
import SpinnerSmall from 'components/SpinnerSmall';
import { IoIosArrowRoundDown, IoIosArrowRoundUp  } from "react-icons/io";
import useGetWindowSize from 'hooks/useGetWindowSize';
import MoveFileModal from './modal/MoveFileModal';
import RenameFileModal from './modal/RenameFileModal';
import { setViewConfirmModal, setConfirmModalData } from 'features/modal/modalSlice';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CiFileOff } from "react-icons/ci";
import NewFileModal from './modal/NewFileModal';
import { SortContainerButtons } from './files.styled'
import { sorted } from './sortUtility'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';  
import useToggleDropdown from 'hooks/useToggleDropdown'
import { DotsWrap, DotEle, IconStyled } from '../dots/dots.styled'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; 
import { CiEdit } from "react-icons/ci";
import Spinner from 'components/Spinner';


export default function Folder({id, title}) {          

    const dispatch = useDispatch()
    const navigate = useNavigate() 
    const { 
        viewRenameFileModal, viewMoveFileModal, widths, viewAddFileModal, 
        selectedFolder, collectionFiles, editNameLoading, editNameSuccess,
        isFolderUpdating, isFolderUpdatedSuccess, isDeleteFolderSuccess
    } = useSelector(state => state.file)
    const { user, admin, } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)

    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const folder = new URLSearchParams(location.search).get('folder') ? new URLSearchParams(location.search).get('folder') : 'none'
    const [ isEditingName, setIsEditingName ] = useState(false)
    const newFolderName = useRef(null)
    const [sortState, setSortState] = useState("ascending");
    const [sField, setSField] = useState("title") 
    const [width, height] = useGetWindowSize()
    const dotsRef2 = useRef()
    const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)
    const [isDotsDropped, setIsDotsDropped] = useToggleDropdown(dotsRef2)

    useEffect(() => { 
        if(Object.keys(selectedFolder).length === 0){ 

            // get local storage data
            let fileCollection = JSON.parse(localStorage.getItem(`${profileChurch.fileCollection}`))

            if(fileCollection){
                
                // find the folder that matches the id in the url and set it as the selected folder
                let folderIndex = fileCollection.folders.findIndex(folder => folder._id === id)

                if(folderIndex !== -1){
                    dispatch(setSelectedFolder(fileCollection.folders[folderIndex]))
                }else{
                    dispatch(setSelectedFolder(null))
                    navigate(`?section=files`)
                }

            }else{
                dispatch(setSelectedFolder(null))
                navigate(`?section=files`)
            }

        }

    }, []) 

    const handleDeleteFolder = () => {
        dispatch(setConfirmModalData({
            text: `Are you sure you want to delete ${selectedFolder.title} folder?`,
            extraInfo: 'This will delete all files in this folder. To keep the files, move them to another folder before deleting this folder.',
            handler: () => dispatch(deleteFolder({
                folderId: selectedFolder._id,
                churchId: profileChurch._id
            })),
            confirmBtnBackgroundHover: '#ff0000',
        }))

        dispatch(setViewConfirmModal(true)) 
    }

    const handleSort = (field) => { 
        setSortState(sortState === 'ascending' ? 'descending' : 'ascending')
        setSField(field)
    }
    
    const handleBack = () => {
        dispatch(setSelectedFolder(null))
        navigate(`?section=files`)
    }
    
    const addFile = () => {
        dispatch(setFolderToAddFile(folder))
        dispatch(setViewFileModal({
            modal: 'viewAddFileModal',
            view: true
        }))
    }

    const handleResetAfterClose = () => {
        dispatch(resetUploadState())
        navigate(`?section=files`)
    }

    const handleNameEdit = () => {
        if(newFolderName.current.value === ''){
            return
        }
        // find the index of the current folder
        let index = collectionFiles.folders.findIndex(folder => folder._id === selectedFolder._id)
        dispatch(renameFolder({
            folderId: selectedFolder._id,
            title: newFolderName.current.value,
            folderIndex: index,
            churchId: profileChurch._id
        })) 
    }

    
    const handleDotsDropdown = () => { 
        setIsDotsDropped.toggle(dotsRef2) 
    }


    useEffect(() => {  
        if(editNameSuccess){
            setIsEditingName(false)
            dispatch(resetUploadState())
        }
    }, [editNameSuccess])

    useEffect(() => {
        if(isDeleteFolderSuccess){
            dispatch(setViewConfirmModal(false))
        }
    }, [isDeleteFolderSuccess])

    useEffect(() => {
        if(width < 768){ 
            dispatch(setWidths({
                title: 200,
                date: 50,
                type: 50
            }))  
        }else{
            dispatch(setWidths({
                title: 200,
                date: 150,
                type: 150
            }))
        }
    }, [width])

    if(selectedFolder === null){
        return (
            <FolderContainer >
            <FolderTop>
                <BackButton onClick={handleBack} > <ArrowBackIcon/>  Back to files </BackButton>        
            </FolderTop>
            </FolderContainer>
        )
    }else if(loading){
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%', 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column', 
                    position: 'relative',
                    animation: 'animate 0.3s',
                    padding: '70px 20px'
                }}
            >
                <Spinner />
            </div>
        )
    }else{
 

        return (
            <FolderContainer >
                <FolderTop>
                    <BackButton onClick={handleBack} > <ArrowBackIcon/>  Back to files </BackButton>        
                </FolderTop>

                <FolderTitle> 
                    <span>
                        <IoFolderOpenOutline style={{fontSize: '30px', marginRight: '10px'}} />

                        {
                            isEditingName ?
                            (
                                <span> 
                                    <NewNameInput 
                                        ref={newFolderName}
                                        type="text"
                                        defaultValue={selectedFolder.title}
                                        />
                                    <SVGSUBMIT
                                        submit={true}
                                        onClick={handleNameEdit}
                                        >
                                            {
                                                editNameLoading ? (
                                                    <SpinnerSmall/>
                                                )
                                                :
                                                (
                                                    <>
                                                        <GoCheck/>
                                                        Set
                                                    </>
                                                )
                                            } 
                                    </SVGSUBMIT>
                                </span>
                            )
                            :
                            (
                                <div onDoubleClick={() => setIsEditingName(true)} >
                                    {
                                        selectedFolder !== null && Object.keys(selectedFolder).length > 0 && selectedFolder.title ? selectedFolder.title : 'Folder'}                     
                                </div>
                            )
                        }                  
                    </span>

                    {(user.isOrganization || admin[profileChurch._id] || (Object.keys(selectedFolder).length > 0 && user._id === selectedFolder.creatorId)) && ( 
                        <>
                            <DotsMoreWrap
                                bgColor='transparent'
                                ref={dotsRef2}
                                onClick={handleDotsDropdown}
                                >
                                <MoreHorizIcon
                                    style={{ fontSize: '21px', marginRight: '0px', cursor: 'pointer'}}
                                    />
                            </DotsMoreWrap>          
                            {
                                isDotsDropped && (
                                    <DotsWrap
                                        top={'20px'}
                                        right={'5px'}
                                        >
                                        <DotEle onClick={addFile} > 
                                            <IconStyled> <VscNewFile /> </IconStyled> 
                                            Add File
                                        </DotEle>
                                        <DotEle  
                                            onClick={() => setIsEditingName(true)}
                                            > 
                                            <IconStyled>  <CiEdit />  </IconStyled> 
                                            Rename Folder
                                        </DotEle>
                                        <DotEle 
                                            style={{color: 'red'}} 
                                            onClick={handleDeleteFolder}
                                            > 
                                            <IconStyled color='red' > <DeleteOutlineIcon /> </IconStyled>

                                            Delete Folder
                                        </DotEle> 
                                    </DotsWrap>
                                )
                            }       
                        </>
                    )}
                    
                </FolderTitle>

                <SortContainerButtons>
                        <button
                            style={{ width: `${widths.title}px` }}
                            onClick={() => handleSort('title')}
                            >
                            Name 
                            {
                                sField === 'title' && (
                                    sortState === 'ascending' ? <IoIosArrowRoundDown/> : <IoIosArrowRoundUp/>
                                )
                            }

                        </button>
                        <button
                            style={{ width: `${widths.date}px`, display: width < 768 ? 'none' : 'flex'}}
                            onClick={() =>  handleSort('createdAt')}
                            >
                            Date
                            {
                                sField === 'createdAt' && (
                                    sortState === 'ascending' ? <IoIosArrowRoundDown/> : <IoIosArrowRoundUp/>
                                )
                            }
                        </button> 
                        <button
                            style={{ width: `${widths.type}px`,display: width < 768 ? 'none' : 'flex' }}
                            onClick={() =>  handleSort('fileType')}
                            >
                            Type
                            {
                                sField === 'fileType' && (
                                    sortState === 'ascending' ? <IoIosArrowRoundDown/> : <IoIosArrowRoundUp/>
                                )
                            }
                        </button>
                    </SortContainerButtons>

                {
                    (Object.keys(selectedFolder).length > 0 && selectedFolder.files.length > 0) ? 
                    (
                        [...selectedFolder.files]
                            .sort(sorted(sField, sortState))
                            .map((file, index) => {
                                return (
                                    <FileItem 
                                        key={file._id}
                                        index={index}
                                        id={file._id}
                                        file={file} 
                                        />
                                )
                        })
                    )
                    :
                    (
                        <div
                            style={{
                                width: '100%',
                                height: '100%', 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexDirection: 'column', 
                                position: 'relative',
                                animation: 'animate 0.3s',
                                padding: '70px 20px'
                            }}
                        >
                            <CiFileOff style={{ fontSize: '50px', color: 'grey', marginBottom: '10px'}} />
                            No files in this folder 
                        </div>
                    )
                } 
            
                <Modal
                    open={viewAddFileModal}
                    onClose={() => dispatch(setViewFileModal({
                        modal: 'viewAddFileModal',
                        view: false
                    }))}
                    >
                    <div
                        style={{
                            width: '100%',
                            height: '100%', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column', 
                            position: 'relative',
                            animation: 'animate 0.3s',
                            padding: '10px 20px'
                        }}
                        >
                            <NewFileModal />  
                    </div>
                </Modal>
                <Modal
                    open={viewMoveFileModal}
                    onClose={() => dispatch(setViewFileModal({
                        modal: 'viewMoveFileModal',
                        view: false
                    }))}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%', 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column', 
                                position: 'relative',
                                animation: 'animate 0.3s',
                                padding: '10px 20px'
                            }}
                            >
                            <MoveFileModal />  
                        </div> 
                </Modal>
                <Modal
                    open={viewRenameFileModal}
                    onClose={() => dispatch(setViewFileModal({
                        modal: 'viewRenameFileModal',
                        view: false
                    }))}
                    >
                    <div
                        style={{
                            width: '100%',
                            height: '100%', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column', 
                            position: 'relative',
                            animation: 'animate 0.3s',
                            padding: '10px 20px'
                        }}
                        >
                            <RenameFileModal />
                    </div>
                </Modal>
                <Modal
                    open={viewConfirmModal}
                    onClose={() => dispatch(setViewConfirmModal(false))}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                            width: '100vw',
                        }}
                        >
                        <ConfirmModal 
                            text={confirmModalData.text}
                            extraInfo={confirmModalData.extraInfo}
                            handler={confirmModalData.handler}
                            confirmBtnBackgroundHover={'#ff0000'}
                            conditionToCloseModal={isFolderUpdatedSuccess}
                            doSomethingAfterClose={true}
                            resetAfterClose={handleResetAfterClose}
                            loadingState={isFolderUpdating}
                            />
                    </div>
                </Modal>           
            </FolderContainer>
        
        )
    }
}

const DotsMoreWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: var(--lightThemeFontColor);
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(172, 172, 172, 0.24);
    &:hover{
        background-color: rgba(172, 172, 172, 0.5);
    
    }
`


const FolderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    color: var(--lightThemeFontColor);

    @media (max-width: 768px) {
        padding:10px;
    }
`

const FolderTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }

`

const FolderTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    color: var(--lightThemeFontColor);
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    position: relative;

    span{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; 
    }

`

const BackButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 40px; 
    color: var(--lightThemeFontColor);
    background-color: var(--lightThemeBackgroundColor);
    border: none; 
    cursor: pointer;
    transition: 0.3s;
    svg{
        margin-right: 5px;
    }
    &:hover{ 
        color: var(--lightThemeBackgroundColor); 
    }

`

// const SVGI = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     margin-right: 0px;
//     color: grey;


//     svg{
//         font-size: 25px;
//         cursor: pointer;
//         transition: 0.3s;
//         color: var(--lightThemeFontColor);
//         margin-right: 0px;
        
//         &:hover{
//             color: var(--lightThemeBackgroundColor);
    
//         }
//     }

//     &:hover{
//         color: #464646;
//     }

// `



const SVGSUBMIT = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    color: white;
    background: #008000ba;
    padding: 3px 11px;
    border-radius: 5px;
    height: 30px; 
    font-size: 12px;
    width: fit-content ;

    svg{
        font-size: 20px;
        cursor: pointer;
        transition: 0.3s;
        color: white;
        margin-right: 10px;
    }

`

const NewNameInput = styled.input`

    width: 150px;
    height: 30px;
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;

`