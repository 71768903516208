import React from 'react'
import { AuthContainer, AuthSideInfo, AuthSideForm, InfoContainer, ICTITLE } from './auth.styled' 
import LoginForm from 'components/auth/LoginForm'


export default function AuthLogin() {
    return (
        <AuthContainer>
            <AuthSideInfo> 
                <InfoContainer>
                    <ICTITLE>Who we are</ICTITLE>
                    <span>A faith-based community platform designed to 
                    connect churches and members, fostering engagement, support, 
                    and spiritual growth—anytime, anywhere.</span>
                </InfoContainer>
            </AuthSideInfo>
            <AuthSideForm>
                <LoginForm />
            </AuthSideForm>
        </AuthContainer>
    )
}


