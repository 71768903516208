import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { redirect as Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header'
import Login from './pages/Login'
import Register from './pages/Register'
import Home from './pages/Home'
import UserProfile from './pages/UserProfile'
import Settings from './pages/Settings'
import Messenger from './pages/Messenger/Messenger'
import MessagePanelMobile from 'components/messenger/MessagePanelMobile'
import Notifications from './pages/Notifications'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import SinglePost from './pages/SinglePost'
import SelectedConversation from './pages/Messenger/SelectedConversation'
import NewConversation from './pages/Messenger/NewConversation'
import ReportIssues from './pages/test/ReportIssues'
import MyEvents from './pages/events/MyEvents'
import UserProfileChurch from './pages/UserProfileChurch'
import MyProfile from './pages/MyProfile'
import Posts from './pages/postsPage/Posts'
import MyPosts from './pages/postsPage/MyPosts'
import PostsFromMemb from './pages/postsPage/PostsFromMemberships'
import Saved from './pages/postsPage/Saved'
import Pricing from 'pages/information/Pricing'
import PrivacyPolicy from 'pages/aboutPraysely/PrivacyPolicy'
import AboutUs from 'pages/landingPage/AboutUs'
import ChurchVerify from 'pages/settings/ChurchVerify'
import FeedbackMain from 'pages/feedback/FeedbackMain'
import BillingPage from './pages/settings/BillingPage'
import NotLoggedInViewChurch from 'pages/NotLoggedInViewChurch'
import SingleEventPage from 'pages/events/SingleEventPage'
import Marketplace from 'pages/Marketplace/Marketplace'
import MyCreatedListingsPage from 'pages/Marketplace/MyCreatedListingsPage'
import MySavedListingsPage from 'pages/Marketplace/MySavedListingsPage'
import { useSelector } from 'react-redux' 
import CreatePostModal from 'components/createPostModal/CreatePostModal'
import StartConversationModal from 'components/messenger/startConversation/StartConversationModal'
import RegisterFromInvite from 'pages/RegisterFromInvite'
import { Modal } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import AudioPlayer from 'components/media/audio/AudioPlayer'
import styled from 'styled-components'
import SingleCampaign from 'pages/giving/SingleCampaign'
import AuthLogin from 'pages/auth/Login'

function App() {

  const { viewCreatePostModal } = useSelector((state) => state.posts)
  const { audioPlayerWindowHeight } = useSelector((state) => state.audioPlayer)
  const { viewStartConversationModal } = useSelector((state) => state.conversations)
  const dispatch = useDispatch()

  useEffect(() => {

    const dis = () => dispatch(addAc({
      type: 'logons', 
    }))

    addAcFunc('lg', dis)

  }, [])




  return (
    <>
      <Router>



        <div className='container'>

 
          <Modal
            open={viewCreatePostModal}  
            >
              <div
                style={{ 
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}  
              > 
              <CreatePostModal />
            </div>
          </Modal>

          {
            viewStartConversationModal && <StartConversationModal />
          }

          <Routes>
            <Route path='/' element={<><Header largerNav={true} /><Home /></>} />
            <Route path='/loginx' element={<AuthLogin />} />
            <Route path='/login' element={<><Header /><Login /></>} />
            <Route path='/register' element={<><Header /><Register /></>} />
            {/* for individual users */}
            <Route path='/user/:profileName' element={<><Header /><UserProfile/></>} />

            {/* for churches */}
            <Route path='/:churchName' element={<><Header /><UserProfileChurch /></>} />  
            <Route path='/church/:churchName' element={<><Header/><NotLoggedInViewChurch /> </>} />

            {/* for users, after user logs in goes here  */}
            <Route path='/home' element={<><Header/><MyProfile /></>} />
            {/* here the <Posts /> component is going to have a prop of USER */}


            {/* <Route path='/:churchName/:section' element={<><Header /><UserProfileChurch /></>} />  */}
            <Route path='/settings' element={<><Header /><Settings /></>} />
            <Route path='/settings/billing' element={<><Header /><BillingPage /></>} />
            <Route path='/notifications' element={<><Header /><Notifications /></>} />
            <Route path='/forgot-password' element={<><Header /><ForgotPassword /></>} />
            <Route path='/reset-password/:userType/:token/:email' element={<><Header /><ResetPassword /></>} />
            
            <Route path='/posts/:postId' element={<><Header /><SinglePost /></>} />
            <Route path='/campaigns/:campaignId' element={<><Header /><SingleCampaign /></>} />
            <Route path='events/:eventId' element={<><Header /><SingleEventPage /></>} />

            <Route path='/anouncements/:anouncementId' element={<><Header /><SinglePost /></>} />
            <Route path='/view-posts' element={<><Header /><Posts /></>} />
            <Route path='/my-posts' element={<><Header /><MyPosts /></>} />
            <Route path='/church-posts' element={<><Header /><PostsFromMemb /></>} />
            <Route path='/saved-posts' element={<><Header /><Saved /></>} />
            <Route path='/messages' element={<><Header /><Messenger /></>} />

           {/* 
                below here there is an optional parameter that is added after the conversation id: "mm"
                this is to diferentiate between a conversation between two members versus a conversation between a member and a church
                a conversation between member and church will NOT have the "mm" parameter.
                Take a look at PRYS-99 in Jira for a screenshot reference to a stackoverflow post that explains how to do this. Link 
                https://stackoverflow.com/questions/35604617/react-router-with-optional-path-parameter
           */}
            <Route path='/messages/:convId/(mm)?/:mm?' element={<><Header /><SelectedConversation /></>} />
            <Route path='/messages/new' element={<><Header /><NewConversation /></>} />
            <Route path='/message/mobile/:convID' element={<><Header/><MessagePanelMobile /></>} />
            {/* <Route path='/myEvents' element={<><Header/><MyEvents /> </>} /> */}

           {/* this route below should be for admins only 12/4/2023 */} 
            {/* <Route path='/reportIssue' element={<ReportIssues />} /> */}

            <Route path='/info/privacy/' element={<><PrivacyPolicy /> </>} />
            <Route path='/info/about/' element={<><Header/><AboutUs /> </>} />
            <Route path='/info/pricing/' element={<><Header/><Pricing /> </>} />
            <Route path='/info/terms/' element={<><PrivacyPolicy /> </>} />
            <Route path='/info/feedback/' element={<><Header/>< FeedbackMain /> </>} />
            <Route path='/settings/verify-account' element={<><Header/><ChurchVerify /> </>} />

            <Route 
              path='/market/listings/(marketListingType)?/:marketListingType?/(listingCategory)?/:listingCategory?/' 
              // listing type will be either For Sale, Service, Jobs, Housing, Vehicles, Wanted
              element={<><Header/><Marketplace /> </>} 
              />

            <Route 
              path='/market/my-listings/' 
              // listing type will be either For Sale, Service, Jobs, Housing, Vehicles, Wanted
              element={<><Header/><MyCreatedListingsPage /> </>} 
              />

            <Route 
              path='/market/my-saved-listings/' 
              // listing type will be either For Sale, Service, Jobs, Housing, Vehicles, Wanted
              element={<><Header/><MySavedListingsPage /> </>} 
              />

            <Route 
              path='/member-register/:addKey/:email'
              element={<><Header/><RegisterFromInvite /> </>} 
              />
              
          </Routes>

          <AudioPlayerContainer
            height={audioPlayerWindowHeight}
            >
            <AudioPlayer />
          </AudioPlayerContainer>

        </div>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App

const AudioPlayerContainer = styled.div`
  height: ${props => props.height};
  width: 500px;
  background-color: white;
  position: absolute;
  bottom: 0;
  right: 150px;
  z-index: 1000;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 5px #ccc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.99s;
`