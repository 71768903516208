import styled from "styled-components";

export const ViewContainer = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    
`

export const BottomWrap = styled.div`  
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`
export const SubmitButton = styled.div`
    width: ${props => props.width ? props.width : '140px' };
    height: 40px;
    background-color: ${props => props.disabled ? 'var(--themeColor)' : 'white'};
    color: var(--themeColor);
    font-weight: 600;
    border-radius: 6px;
    border: 3px solid var(--themeColor);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'}; 
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover{
        background-color: var(--themeColor);
        color: white;
    }
`

export const FormWrap = styled.div`
    display: flex;  
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;

    @media (max-width: 768px){
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

`

export const PaymentProcessContainer = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
 
`

export const LeftSide = styled.div`
    font-size: 15px;
    color: var(--lightThemeFontColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 40%; 
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ccc;

    h3{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        font-weight: 600;
        margin-top: 20px;
        span{
            margin-left: 0px;
        }
    }

    @media (max-width: 768px){
        width: 70%; 
        height: 450px;
    }

`
export const GiveForm = styled.div`
    padding: 20px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;

    @media (max-width: 768px){
        width: 100%; 
        height: 400px;
        margin-top: 30px;
        justify-content: flex-start;
        align-items: center;
    }
`


export const GivingButton = styled.button`
    pointer-events: ${props => props.active ? 'none' : 'auto'};
    background-color: ${props => props.active ? 'var(--themeColor)' : 'white'};
    border: none;
    box-shadow: 0px 0px 5px #ccc;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    color: ${props => props.active ? 'white' : 'var(--lightThemeFontColor);'};
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;

    &:hover{
        background-color: #e1e1e1;
    }

    svg{
        margin-right: 10px;
    }

`

export const TransactionsTableContainer = styled.table`
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    cursor: context-menu; 
    color: #585858;

    th { 
        border: 1px solid #dddddd;
        text-align: center;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }
 
    td {
        color: #585858;
        position: relative;
        text-align: center;
        font-size: 12px;
        border: 1px solid #dddddd;
        padding: 2px;
    }

    @media (max-width: 768px){
        th{
            font-size: 10px;
            padding: 3px;
        }
        td{
            font-size: 9px;
            padding: 0px;
        }
    }

`

export const StyledInput = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    width: 100%;
    color: var(--lightThemeFontColor);

    label{
        font-size: 12px;  
    }

    @media (max-width: 768px){
        width: 100%;
    }
`
export const InputIconWrap = styled.div`
    width: 100%; 
    transition: all 0.6s ease-in-out;
    border: ${props => props.isError ? '2px solid red' : '2px solid transparent'};
    border-radius: 7px;

    input{
        width: 100%;
        padding: 15px;
        border: 2px solid #cccccc85;
        border-radius: 5px;
        outline: none;
    }

    @media (max-width: 768px){
        /* width: 90%; */
    }

    /* focus within */

    input:focus{
        
        transition: all 0.6s ease-in-out;

        border: 2px solid var(--themeColor);
    }

`

export const DH1 = styled.h1`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--lightThemeFontColor);
    svg {
        margin-right: 10px;
        font-size: 25px;
    }

`

export const DonateContainer = styled.div` 
    position: relative;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: white ;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ccc;
    min-height: 400px;
    height: fit-content;
    max-height: ${props => props.maxHeight };
    overflow-y: scroll;
    /* hide scroll bar */
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 768px){
        margin-bottom: 110px;
    }
`