import { useSelector, useDispatch } from 'react-redux'
import TimeAgo from 'timeago-react'
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { punc } from '../../funcs/punc'
import { useEffect, useRef } from 'react';
import useToggleDropdown from '../../hooks/useToggleDropdown';
import { MdOutlineReport, MdOutlineSaveAlt } from 'react-icons/md'
import { VscSend } from "react-icons/vsc";

import { setViewModal } from '../../features/modal/modalSlice';
import { savePost, postResetSaved } from '../../features/posts/postSlice';
import { imgUrl } from 'features/constants';
import { toast } from 'react-toastify';
import styled from 'styled-components'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { DotsWrap, DotsButton2 } from '../dots/dots.styled';
import { language } from 'app/language';

import * as timeago from 'timeago.js';
// import it first.
import es from 'timeago.js/lib/lang/es';

// register it.
timeago.register('es', es);


function SinglePostTop() {
    const dispatch = useDispatch()
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const handleDropDown = () => { setIsDropped.toggle(ref1) }
    const { singlePost, isPostSuccess } = useSelector((state) => state.singlePost)
    const { isPostSaved } = useSelector((state) => state.posts)
    const { user } = useSelector((state) => state.auth)

    const handleReport = () => {
        dispatch(setViewModal({
            postId: singlePost._id,
            postText: singlePost.postText.text,
            creatorId: singlePost.user._id,
            residingId: singlePost.postedToId._id,
            postedToId: singlePost.postedToId._id,
            postType: 'post',
        }))
    }
    
    const handleSendMessage = () => {
    }

    const handleSavePost = () => {
        dispatch(savePost({
            postId: singlePost._id
        }))
    }
    
    useEffect(() => {
        if(isPostSaved){
          toast.success('Post Saved',
            {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          })
          dispatch(postResetSaved())
        }
      }, [isPostSaved, dispatch])
    
    let newDate = new Date(singlePost.createdAt)

    let profileImageToShow;
    try{ profileImageToShow =  `${imgUrl}/profile/${singlePost.user.profileImg.imagePath2}`}
    catch(e){  profileImageToShow =  require(`../../profileImage/generic.png`)  }

    let postImageToShow;
    try{ postImageToShow =  `${imgUrl}/posts/${singlePost.image.imagePath2}`;}
    catch(e){  postImageToShow =  require(`../../postImages/postImageError.png`)  }

    return (
        <SinglePostTopContainer>
            <TopSection>
                <SinglePostTopLeft>
                    <SinglePostTopInfo>
                        <div style={{marginRight: '10px'}}>
                            { isPostSuccess && singlePost.user.profileImg.imagePath2 ?  
                                (
                                    singlePost.isAnonymous ? 
                                    ( <AccountCircleIcon /> )
                                    :
                                    ( <img className='singlePostProfileImage' src={profileImageToShow} alt='generic.png'/> )
                                )
                                :
                                (
                                    <img className='singlePostProfileImage' src={require(`../../profileImage/generic.png`)} alt='generic.png'/> 
                                )
                            } 
                        </div>
                    
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {
                                singlePost.isAnonymous ? 
                                ( 
                                    <i>
                                        {language.anonymous[user.preferredLanguage]}
                                    </i> 
                                )
                                :
                                ( punc(singlePost.user.fullName) ) 
                            } 
                            <div style={{fontSize: '11px', marginTop: '5px'}} >
                                <TimeAgo 
                                    datetime={newDate}
                                    locale={user.preferredLanguage}
                                    />
                            </div>
                            <PostType> 
                                {language[singlePost.postType][user.preferredLanguage]}
                            </PostType>
                        </div> 
                    </SinglePostTopInfo> 
                </SinglePostTopLeft>

                <div className='singlePostTopRight'>
                    <DotsButton2
                        ref={ref1}
                        onClick={handleDropDown}
                        >
                        <HiOutlineDotsHorizontal style={{fontSize: "25px"}}/> 
                    </DotsButton2>
                    {isDropped && (
                        <DotsWrap 
                            top='30px'
                            right='20px'
                            >
                            <DotEle onClick={handleSavePost}> 
                                <MdOutlineSaveAlt /> Save 
                            </DotEle>
                            {
                                singlePost.user._id !== user._id && (
                                    <DotEle onClick={handleSendMessage}  > 
                                        <VscSend /> Direct Message
                                    </DotEle>
                                )
                            } 
                            <DotEle onClick={handleReport}  > 
                                <MdOutlineReport /> Report
                            </DotEle>
                        </DotsWrap>
                    )}
                </div>
            </TopSection>
            <MainSection>
                <SinglePostText>
                    {
                        singlePost.postText.text.split('\n').map((subtext) => {
                            return (
                              <span
                                style={{
                                  display: 'flex', 
                                  marginTop: '5px',
                                  fontSize: '17px'
                                }}
                                >
                                {subtext}  
                              </span>
                            )
                        }
                    )
                }
                </SinglePostText>
                {
                    isPostSuccess && singlePost.image &&
                    (
                        <img src={postImageToShow} alt='logo.png'/>
                    )
                }
            
            </MainSection>
        </SinglePostTopContainer>
    )  
}

const DotEle = styled.div`
    padding: 10px 20px;
    color: var(--lightThemeFontColor);
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 7px;
    white-space: nowrap;
    transition: .5s;
    cursor: pointer;

    &:hover {
        background-color: rgb(239, 239, 239); 
    }

    svg {
        margin-right: 10px;
        font-size: 19px;
    }

`

const SinglePostTopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    width: 100%; 

`

const SinglePostTopInfo = styled.div`
    display: flex;
    flex-direction: row;
    text-align: left; 
    align-items: center;

    @media (max-width: 768px) {
        font-size: 12px;
    }

`

const SinglePostTopContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; 
`

const TopSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    color: #75797a; 

    @media (max-width: 868px) {
        margin-top: 10px;
        padding: 10px;
    }
`

const MainSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    color: #606364;

    @media (max-width: 868px) {
        margin-top: 10px;
        padding: 10px 5px;
    }
`

const SinglePostText = styled.div`
    width: 100%;
    min-height: 75px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    padding: 0px 20px 20px 20px;
    color: #6a6d6e;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    text-align: left;
`


const PostType = styled.div`
    padding: 6px 12px;
    background-color: #008000ab;
    color: white;
    border-radius: 8px;
    height: fit-content;
    font-size: 11px; 
    margin-top: 5px;
    width: fit-content;

    @media (max-width: 768px) {
        font-size: 10px;
    }

`

export default SinglePostTop