import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getChurchTransactions } from 'features/giving/givingSlice'
import styled from 'styled-components'
import { GivingButton } from 'components/giving/giving.styled'
import { useNavigate, useLocation } from 'react-router-dom'
import Transactions from './VerifiedConnectAcct/ChurchTransactions'
import Payouts from './VerifiedConnectAcct/Payouts'
import Banks from './VerifiedConnectAcct/Banks'

export default function VerifiedConnectAccount() {

    const location = useLocation()
    const navigate = useNavigate() 
    const subsection = new URLSearchParams(location.search).get('verified-accounts') ? new URLSearchParams(location.search).get('verified-accounts') : 'none'
 

    useEffect(() => {
        navigate('?section=giving&verified-accounts=transactions')
    }, [])

    const handleNav = (value) => {
        navigate(`?section=giving&verified-accounts=${value}`)
    }

    return (
        <VAC>
            <ButtonWrap>
                <GivingButton
                    onClick={() => handleNav('banks')}
                    active={subsection === 'banks'}
                    >
                    Banks
                </GivingButton>  
                <GivingButton
                    onClick={() => handleNav('transactions')}
                    active={subsection === 'transactions'}
                    >
                    Transactions
                </GivingButton>
                <GivingButton
                    onClick={() => handleNav('payouts')}
                    active={subsection === 'payouts'}
                    >
                    Payouts
                </GivingButton>
            </ButtonWrap>
 
            {
                subsection === 'transactions' ? (
                    <Transactions />
                )
                : subsection === 'payouts' ? (
                    <Payouts />
                )
                : subsection === 'banks' &&
                (
                    <Banks />
                )
            } 

        </VAC>
    )
}

const VAC = styled.div`
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%; 
`

const ButtonWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  

`