import React, { useState, useRef } from 'react'
import styled from 'styled-components'   
import { TbBuildingChurch } from "react-icons/tb";
import PersonIcon from '@mui/icons-material/Person';
import { setRegistrationType } from 'features/auth/authSlice';
import useGetWindowSize from 'hooks/useGetWindowSize';

// ************* transitions *************
import './styles.css';
import { CSSTransition } from 'react-transition-group';  
// ************* transitions *************

import { useDispatch } from 'react-redux';

export default function ChooseUserType({ handler }) {
    
    const dispatch = useDispatch()
    const [church, setChurch] = useState(false);
    const [user, setUser] = useState(false); 
    const nodeRef = useRef(null);
    const [width, height] = useGetWindowSize();

    const handleRegisterType = (type) => {
        console.log(height)
        handler(true)
        localStorage.setItem('registrationType', type)
        localStorage.setItem('loginType', type)
        dispatch(setRegistrationType(type))
    }

    return (
        <ChoiceContainer>
            <h1>Select an account type to register...</h1>


{/* Transition group should be remove and replaced with transition state */}

            <ChoiceItemWrapper>    
                <CSSTransition
                    in={church}
                    nodeRef={nodeRef}
                    timeout={300} 
                    unmountOnExit
                    classNames="item" 
                    >     

                    <RegisterTooltip
                        className='item'
                        ref={nodeRef}
                        position='left' 
                        >
                        <span>Register a church</span>
                    </RegisterTooltip>
                        
                </CSSTransition>

                <CSSTransition
                    in={user}
                    nodeRef={nodeRef}
                    timeout={300} 
                    unmountOnExit
                    classNames="item" 
                    >     

                    <RegisterTooltip
                        className='item'
                        ref={nodeRef}
                        position='right' 
                        > 
                        <span>Register as a church member</span>
                    </RegisterTooltip>
                        
                </CSSTransition>


                <ChoiceItem
                    position='left'
                    onClick={() => handleRegisterType('church')}
                    onMouseEnter={() => {  setChurch(true)}}
                    onMouseLeave={() => {  setChurch(false)}} 
                    >  

                    <TbBuildingChurch />
                        <p>
                            Church Organization
                        </p> 
                </ChoiceItem>
                <ChoiceItem
                    position='right'
                    onClick={() => handleRegisterType('user')}
                    onMouseEnter={() => {  setUser(true)}}
                    onMouseLeave={() => {  setUser(false)}} 
                    >  
                    < PersonIcon />
                        <p>
                            Individual User
                        </p> 
                </ChoiceItem>
            </ChoiceItemWrapper>
        </ChoiceContainer>
    )
}

const ChoiceItemWrapper = styled.div`
    margin-top: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: fit-content;
    height: fit-content;


    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
`


const RegisterTooltip = styled.div`
    color: var(--lightThemeFontColor);
    position: absolute;
    top: -100px;
    left: ${({ position }) => position === 'left' ? '10%' : 'unset'};
    right: ${({ position }) => position === 'right' ? '5%' : 'unset'};


`

const ChoiceContainer = styled.div`
    height: 100%; 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    h1{
        color: var(--lightThemeFontColor);
        font-size: 30px;
        margin-top: 160px;
        margin-bottom: 90px;
        font-weight: 400;
    }

    @media (max-width: 768px) {
        h1{
            margin-top: 60px;
            margin-bottom: 40px;
            font-size: 18px;
        }
    }
`

const ChoiceItem = styled.div`
    width: 250px;
    height: 200px;
    /* border: 1px solid var(--lightThemeFontColor); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--lightThemeFontColor); 
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 0px 8px #b1b1b1;
    margin-right: ${props => props.position === 'left' ? '20px' : 'unset'};
    margin-left: ${props => props.position === 'right' ? '20px' : 'unset'};
    gap: 14px;

    svg{
        color: var(--lightThemeFontColor);
        font-size: 40px; 
    }

    p{
        font-size: 15px; 
    }

    @media (max-width: 768px) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
        width: 150px;
        height: 120px;
    }

    div{
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 10px;
    
    }

`
 