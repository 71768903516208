 
export const language = {

    noAnnouncements: {
        en: 'No announcements',
        es: 'No hay anuncios'
    },

    errorOccurred: {
        en: 'An error occurred',
        es: 'Ocurrió un error'
    },

    home: {
        en: 'Home',
        es: 'Inicio'
    },

    goToChurch: {
        en: 'Go to Church',
        es: 'Ir a Iglesia'
    },

    churchMembership: {
        en: 'My Church Membership',
        es: 'Mi Membresía de Iglesia'
    },
    
    myChurches: {
        en: 'My Churches',
        es: 'Mis Iglesias'
    },

    myChurch: {
        en: 'My Church',
        es: 'Mi Iglesia'
    },

    loading: {
        en: 'Loading...',
        es: 'Cargando...'
    },

    chooseLanguage: {
        en: 'Choose your preferred language', 
        es: 'Seleccione su idioma preferido'
    },

    logout: {
        en: 'Logout',
        es: 'Salir'
    },

    churchNavigation: {
        en: ' shortcuts',
        es: ' accesos directos'
    },

    startWriting: {
        en: 'Start writing here...',
        es: 'Comienza a escribir aqui...'
    },
    postType: {
        en: 'Post Type',
        es: 'Tipo de Publicación'
    },
    addImage: {
        en: 'Add Image',
        es: 'Añadir Imagen'
    },
    add: {
        en: 'Add',
        es: 'Añadir'
    },
    replace: {
        en: 'Replace',
        es: 'Reemplazar'
    },
    submitPost: {
        en: 'Submit Post',
        es: 'Crear Publicación'
    }, 
    submit: {
        en: 'Submit',
        es: 'Enviar'
    },
    
    image: {
        en: 'Image',
        es: 'Imagen'
    },
    anonymous: {
        en: 'Anonymous',
        es: 'Anónimo'
    },
    postingTo: {
        en: 'Posting To',
        es: 'Publicando en'
    },
    selectWhereToPost: {
        en: 'Select where to post...',
        es: 'Selecciona dónde publicar...'
    },
    messages: {
        en: "Messages",
        es: "Mensajes"
    },
    conversations: {
        en: "Conversations",
        es: "Conversaciones"
    },
    feedbacks: {
        en: "Feedbacks",
        es: "Retroalimanetaciónes"     
    },
    searchChurchMembers: {
        en: 'Search Church Members',
        es: 'Buscar Miembros de la Iglesia' 
    },
    selectConversation: {
        en: 'Select a conversation to view messages',
        es: 'Seleccione una conversación para ver mensajes'
    }, 
    newMessage: {
        en: "New Message",
        es: "Nuevo Mensaje"
    },
    settings: {
        en: "Settings",
        es: "Ajustes"
    },
    inviteMembers: {
        en: "Invite Members",
        es: "Invitar Miembros"
    },
    addMembersByInvite: {
        en: "Add Members by Invite",
        es: "Agregar Miembros por Invitación"
    
    },
    inviteMembersDescripton: {
        en: "Invite members to join your church",
        es: "Invita a los miembros a unirse a tu iglesia"
    },
    
    sendBulkInvites: {
        en: "Send Bulk Invites",
        es: "Enviar Invitaciones Masivas"
    },
    sendSingleInvite: {
        en: "Send Single Invite",
        es: "Enviar Invitación Individual"
    },
    enterEmailAddress: {
        en: "Enter email address",
        es: "Ingrese dirección de correo electrónico"
    },

    enterEmailAddresses: {
        en: "Enter email addresses separated by commas",
        es: "Ingrese direcciones de correo electrónico separadas por comas"
    },


    memberManagement: {
        en: "Member Management",
        es: "Gestión de Miembros"
    },
    searchMembers: {
        en: "Search Members",
        es: "Buscar Miembros"
    },

    removeFromMembership: {
        en: "Remove from Membership",
        es: "Eliminar de la Membresía"
    },
    
    makeAdmin: {
        en: "Assign as Admin",
        es: "Asignar como Administrador"
    },

    removeAdmin: {
        en: "Remove as Admin",
        es: "Eliminar como Administrador"
    },

    requestsReceived: {
        en: "Requests Received",
        es: "Solicitudes Recibidas"
    },

    noRequests: {
        en: "No requests to show",
        es: "No hay solicitudes para mostrar"
    },

    loadMore: {
        en: "Load More",
        es: "Ver Más"
    },
    makeModerator: {
        en: "Assign as Moderator",
        es: "Asignar como Moderador"
    },

    removeModerator: {
        en: "Remove as Moderator",
        es: "Eliminar como Moderador"
    },
    "Show All": {
        en: "Show All",
        es: "Mostrar Todo"
    },
    "Prayers": {
        en: "Prayers",
        es: "Peticiones de Oración"
    },
    "Praises": {
        en: "Praises",
        es: "Alabanzas"
    },
    "Testimonies": {
        en: "Testimonies",
        es: "Testimonios"
    },
    notifications: {
        en: "Notifications",
        es: "Notificaciones"
    },
    goBack: {
        en: 'Go Back',
        es: 'Regresar'
    },
    createPost: {
        en: 'Create Post',
        es: 'Crear Publicación'
    },
    posts: {
        en: 'Posts',
        es: 'Publicaciones'
    },
    filterPosts: {
        en: 'Filter Posts',
        es: 'Filtrar Publicaciones'
    },
    "Prayer Request": {
        en: 'Prayer Request',
        es: 'Petición de Oración'
    },
    "Testimony": {
        en: 'Testimony',
        es: 'Testimonio'
    },
    "Praise": {
        en: 'Praise',
        es: 'Alabanza'
    },
    prayers: {
        en: 'Prayers',
        es: 'Oraciones'
    },
    praises: {
        en: 'Praises',
        es: 'Alabanzas'
    },
    testimonies: {
        en: 'Testimonies',
        es: 'Testimonios'
    },
    prayerWall: {
        en: 'Prayer Wall',
        es: 'Muro de Oración'
    },
    viewPrayerWall: {
        en: 'View Prayer Wall',
        es: 'Ver Muro de Oración'
    },
    hidePrayerWall: {
        en: 'Hide Prayer Wall',
        es: 'Ocultar Muro de Oración'
    },
    comment: {
        en: 'Comment',
        es: 'Comentar'
    },
    comments: {
        en: 'Comments',
        es: 'Comentarios'
    },
    viewPost: {
        en: 'View Post',
        es: 'Ver Publicación'
    },
    save: {
        en: 'Save',
        es: 'Guardar'
    },
    addToPrayerWall: {
        en: 'Add to Prayer Wall',
        es: 'Agregar al Muro de Oración'
    },
    delete: {
        en: 'Delete',
        es: 'Eliminar'
    },
    report : {
        en: 'Report',
        es: 'Reportar'
    },
    pray: {
        en: 'Pray',
        es: 'Orar'
    },
    youArePraying: {
        en: 'You are praying',
        es: 'Estás orando'
    },
    noCommentsYet: {
        en: 'No comments yet',
        es: 'Aún no hay comentarios'
    },
    startWritingComment: {
        en: 'Start writing a comment',
        es: 'Empieza a escribir un comentario'
    },
    announcements: {
        en: 'Announcements',
        es: 'Anuncios'
    },
    startAnnouncementHere: {
        en: 'Start announcement here...',
        es: 'Comienza el anuncio aquí...'
    },
    createEvent: {
        en: 'Create Event',
        es: 'Crear Evento'
    },
    viewCalendar: {
        en: 'View Calendar',
        es: 'Ver Calendario'
    },
    setLocation: {
        en: 'Set Location',
        es: 'Establecer Ubicación'
    },
    members: {
        en: 'Members',
        es: 'Miembros'
    },
    public: {
        en: 'Public',
        es: 'Público'
    },
    eventDescription: {
        en: 'Event Description',
        es: 'Descripción del Evento'
    },
    createEvent: {
        en: 'Create Event',
        es: 'Crear Evento'
    },
    membersDescription: {
        en: 'Only logged in members can see this event',
        es: 'Solo los miembros pueden ver este evento'
    },
    publicDescription: {
        en: 'Anyone with the link can see this event',
        es: 'Cualquiera con el enlace puede ver este evento'
    },

    discussionEnabled: {
        en: 'Discussion Enabled',
        es: 'Discusión Habilitada'
    },
    discussionDisabled: {
        en: 'Discussion Disabled',
        es: 'Discusión Deshabilitada'
    },
    discussionEnabledDescription: {
        en: 'Members can discuss this event',
        es: 'Los miembros pueden discutir este evento'
    },
    remove: {
        en: 'Remove',
        es: 'Eliminar'
    },
    clear: {
        en: 'Clear',
        es: 'Limpiar'
    },
    viewItems: {
        en: 'View Items',
        es: 'Ver Elementos'
    },
    selectStartDate: {
        en: 'Select Start Date',
        es: 'Seleccionar Fecha de Inicio'
    },
    endingDate: {
        en: 'Ending Date?',
        es: 'Fecha de Finalización?'
    },
    until: {
        en: 'Until',
        es: 'Hasta'
    },
    eventLocation: {
        en: 'Event Location',
        es: 'Ubicación del Evento'
    },
    eventName: {
        en: 'Event Name',
        es: 'Nombre del Evento'
    },
    createNewEvent: {
        en: 'Create New Event',
        es: 'Crear Nuevo Evento'
    },
    updateEvent: {
        en: 'Update Event',
        es: 'Actualizar Evento'
    },
    eventName: {
        en: 'Event Name',
        es: 'Nombre del Evento'
    },
    eventDescription: {
        en: 'Event Description',
        es: 'Descripción del Evento'
    },
    startDate: {
        en: 'Start Date',
        es: 'Fecha de Inicio'
    },
    endDate: {
        en: 'Has End Date?',
        es: 'Fecha de Finalización'
    },
    eventLocation: {
        en: 'Event Location',
        es: 'Ubicación del Evento'
    },
    setLocation: {
        en: 'Set Location',
        es: 'Establecer Ubicación'
    },
    discussionEnabled: {
        en: 'Discussion Enabled',
        es: 'Discusión Habilitada'
    },
    createAnnouncement: {
        en: 'Create Announcement',
        es: 'Crear Anuncio'
    },
    startAnnouncement: {
        en: 'Start Announcement',
        es: 'Iniciar Anuncio'
    },
    sendEmailToMembers: {
        en: 'Send Email to Members',
        es: 'Enviar Email a Miembros'
    }, 
    newest: {
        en: 'Newest First',
        es: 'Más Recientes Primero'
    },
    oldest: {
        en: 'Oldest First',
        es: 'Más Antiguos Primero'
    },
    dateAdded: {
        en: 'Date Added',
        es: 'Fecha Agregada'
    }, 
    uploadVideo: {
        en: 'Upload Video',
        es: 'Subir Video'
    },
    uploadAudio: {
        en: 'Upload Audio',
        es: 'Subir Audio'
    },
    addPeople: {
        en: 'Add People',
        es: 'Agregar Personas'
    },
    editDescription: {
        en: 'Edit Description',
        es: 'Editar Descripción'
    },
    uploadFile: {
        en: 'Upload File',
        es: 'Subir Archivo'
    },
    renameFile: {
        en: 'Rename File',
        es: 'Renombrar Archivo'
    },
    deleteFile: {
        en: 'Delete File',
        es: 'Eliminar Archivo'
    },
    name: {
        en: 'Name',
        es: 'Nombre'
    },
    type: {
        en: 'Type',
        es: 'Tipo'
    },
    size: {
        en: 'Size',
        es: 'Tamaño'
    },
    date: {
        en: 'Date',
        es: 'Fecha'
    },
    moveFile: {
        en: 'Move File',
        es: 'Mover Archivo'
    }, 
    createFolder: {
        en: 'Create Folder',
        es: 'Crear Carpeta'
    },
    feedbackHeading: {
        en: 'Give your church feedback',
        es: 'Dé su opinión a su iglesia'
    },
    feedbackDescription: {
        en: 'Have a thought, suggestion, and/or feedback that you want to tell your church?',
        es: '¿Tiene un pensamiento, sugerencia y/o opinión que desea decir a su iglesia?' 
    },
    feedbackInfo: {
        en: 'This will not be shared publicly with fellow members, only your church will be able to view this feedback.',
        es: 'Esto no se compartirá públicamente con otros miembros, solo su iglesia podrá ver esta retroalimentación.'
    },
    
    seeWhoIsPraying: {
        en: 'See who is praying',
        es: 'Ver quién está orando'
    },
    praiseTheLord: {
        en: 'Praise the Lord',
        es: 'Alaba al Señor'
    },
    praisingTheLord: {
        en: 'Praising the Lord',
        es: 'Alabando al Señor'
    }, 

    Bulletin: {
        en: 'Bulletin',
        es: 'Boletín'
    },
    Polls: {
        en: 'Poll',
        es: 'Encuesta'
    },
    Media : {
        en: 'Media',
        es: 'Media'
    },
    Directory: {
        en: 'Directory',
        es: 'Directorio'
    },
    Events: {
        en: 'Events',
        es: 'Eventos'
    },
    Announcements: {
        en: 'Announcements',
        es: 'Anuncios'
    },
    Files: {
        en: 'Files',
        es: 'Archivos'
    },
    Feedback: {
        en: 'Feedback',
        es: 'Retroalimentación'
    },
    About: {
        en: 'About',
        es: 'Acerca de'
    },
    Posts: {
        en: 'Posts',
        es: 'Publicaciones'
    },
    events: {
        en: 'Events',
        es: 'Eventos'
    },
    Giving: {
        en: 'Giving',
        es: 'Dar'
    },
    about: {
        en: 'About',
        es: 'Acerca de'
    },
    addPeople: {
        en: 'Add People',
        es: 'Agregar Personas'
    },
    editDescription: {
        en: 'Edit Description',
        es: 'Editar Descripción'
    },
    description: {
        en: 'Description',
        es: 'Descripción'
    },
    details: {
        en: 'Details',
        es: 'Detalles'
    },
    showMore: {
        en: 'Show More',
        es: 'Mostrar Más'
    },
    showLess: {
        en: 'Show Less',
        es: 'Mostrar Menos'
    },
    dateJoined: {
        en: 'Date Joined',
        es: 'Fecha de Ingreso'
    },
    location: {
        en: 'Location',
        es: 'Ubicación'
    },
    members: {
        en: 'Members',
        es: 'Miembros'
    },
    people: {
        en: 'Who we are',
        es: 'Quiénes somos'
    },
    churchDescriptonPlaceholder: {
        en: 'Enter a description of your church',
        es: 'Ingrese una descripción de su iglesia'
    },
    success: {
        en: 'Success',
        es: 'Éxito'
    },
    addLocation: {
        en: 'Add Location',
        es: 'Agregar Ubicación'
    },
    startTime: {
        en: "Pick a start time",
        es: "Elija una hora de inicio"
    }
    
};