import React, { useEffect, useState } from 'react'
import { churchConnectAccountCheck, setstartCreatingAccount } from 'features/giving/givingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SettingsMainContainer, TopHeaderSettings } from '../settings.styled' 
import Loader from 'loaders/Loader'
import ChurchConnectAccount from './ChurchConnectAccount'
import styled from 'styled-components'
import CreateConnectAcctForm from './CreateConnectAcctForm'
import { givingLanugauge } from './givingSettingsLanguage'

export default function Giving() {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const { 
        startCreatingAccount,
        churchConnectAccountCheckLoading,
        churchConnectAccountCheckSuccess,
        churchConnectAccountCheckError,
        churchConnectAccountCheckMessage
        
    } = useSelector(state => state.giving)


    const [start, setStart] = useState(false)

    useEffect(() => { 
        // check if church is setup for receiving payments 


        dispatch(churchConnectAccountCheck( )) 
        
        

        // in the future we will make a way so that each church automatically gets a connnect account
        // on registration... so instead of checking if the church has a connect account, we will 
        // check if the church has any missing information that needs to be completed.

    }, [startCreatingAccount])


    if(churchConnectAccountCheckLoading){
        return (
            <SettingsMainContainer>
                <TopHeaderSettings>
                    {
                        givingLanugauge.memberGiving[user.preferredLanguage]
                    }
                </TopHeaderSettings> 
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }}
                    >
                    <Loader />
                </div> 
            </SettingsMainContainer>
        )
    }else{
        return (
            <SettingsMainContainer>
                <TopHeaderSettings>
                    {
                        givingLanugauge.memberGiving[user.preferredLanguage]
                    }
                </TopHeaderSettings> 


                { 
                    churchConnectAccountCheckSuccess ?
                    (
                        <ChurchConnectAccount />
                    )
                    : startCreatingAccount ?
                    (
                        <CreateConnectAcctForm />
                    )
                    : churchConnectAccountCheckError &&
                    (
                        churchConnectAccountCheckMessage === 'Not premium' ?
                        (
                            <div>
                                Praysely premium is required to receive payments.
                            </div>
                        )
                        : churchConnectAccountCheckMessage === 'No Account' && 
                        (
                            <div 
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                
                                }}
                                >
                                <div>
                                    {
                                        givingLanugauge.noPrayselyGivingAcct[user.preferredLanguage]
                                    }
                                </div>
                                <StartGivingButton
                                    onClick={() => dispatch(setstartCreatingAccount(true))}
                                    >
                                    {
                                        givingLanugauge.startCreatingAccount[user.preferredLanguage]
                                    }
                                </StartGivingButton>
                            </div>
                        )  
                    ) 
                } 
            </SettingsMainContainer>
        )        
    }


}

const StartGivingButton = styled.button`
    padding: 10px;
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; 

    &:hover{
        background-color: green;
        color: white;
        box-shadow: 0px 0px 15px #ccc;
    }
`