import { useParams } from 'react-router-dom'
import { useState, useEffect, useRef}  from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MdArrowBackIosNew } from 'react-icons/md'
import { getSingleCampaign, getSingleCampaignPublic, setViewShareModal } from 'features/givingCampaigns/givingCampaignSlice'
import { imgUrl, dateObj } from 'features/constants'
import styled from 'styled-components'
import Loader from 'loaders/Loader'
import Details from '../../components/events/singlePageEventComponents/Details'
import Discussion from '../../components/events/singlePageEventComponents/Discussion' 
import useImageLoader from 'hooks/useImageLoader'
import { eventLanguage } from 'components/events/eventsLanguage'
import Donate from 'components/giving/campaigns/Donate'
import Donations from 'components/giving/campaigns/Donations' 
import { Modal } from '@mui/material'
import ShareCampaignModal from 'components/giving/campaigns/ShareCampaignModal'
import { MUIModalWrap } from 'components/modal/modal.styled'
import { useMetaTags } from 'react-metatags-hook';
import { BiDonateHeart } from "react-icons/bi";
import { GiLoveLetter } from "react-icons/gi";
import { IoIosArrowBack, IoIosArrowForward  } from "react-icons/io";

import {
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
  } from 'react-share';

export default function SingleCampaign( ) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { campaignId } = useParams()
    const { singlePageEventData, isSingleEventLoading,  } = useSelector((state) => state.events)
    const [date, setDate] = useState(null) // ['detail', 'discussion' 
    const { singleCampaignData, getSingleCampaignLoading, church, viewShareModal } = useSelector((state) => state.givingCampaign)
    const ref = useRef(null)

    const [currentImage, setCurrentImage] = useState(0)

    const [imageSource, { blur }] = useImageLoader(
        `${imgUrl}/givingCampaigns/${singleCampaignData && singleCampaignData.images && singleCampaignData.images[currentImage].imagePath2}`, 
        `${imgUrl}/givingCampaigns/${singleCampaignData && singleCampaignData.images && singleCampaignData.images[currentImage].imagePath2}`
    )

    const [ contentView, setContentView ] = useState('donations') // ['donations', 'donate' 

    const handleImageChange = (direction) => {
        if(direction === 'left'){
            if(currentImage === 0){

                setCurrentImage(singleCampaignData.images.length - 1)
            }else{
                setCurrentImage(currentImage - 1)
            }
        }else{
            if(currentImage === singleCampaignData.images.length - 1){
                setCurrentImage(0)
            }else{
                setCurrentImage(currentImage + 1)
            }
        }
    }

    useEffect(() => {
        if(singleCampaignData === null){ 
            if(user){  
                dispatch(getSingleCampaign({
                    campaignId: campaignId,
                    userId: user._id
                }))

            }else{  
                dispatch(getSingleCampaignPublic({
                    campaignId: campaignId
                }))
            }
        } else{ 
 
        }        
    }, [])

    const handleClick = () => {
        navigate(-1)
    }

    const scrollingBottom = () => {
        const e = ref;
    
        e.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      };

    const handleShare = () => {
         
        dispatch(setViewShareModal(true))

    }

 

  return (
        <SinglePageContainer>

            {   user  && 
                (
                    <div 
                        
                        className='singlePostPostedToWrap'
                        style={{
                            marginTop: '20px',

                        }}
                        >
                        <div className='singlePostGoBack' onClick={handleClick} > 
                            <MdArrowBackIosNew style={{marginRight: '10px'}} /> Go Back
                        </div>
                    </div>
                ) 
            }


            {
                singleCampaignData && singleCampaignData.title && (
                    <CampaignTitle>
                        {singleCampaignData.title}
                    </CampaignTitle>
                )
            }



                            
            {
                singleCampaignData && (

                    getSingleCampaignLoading ? (
                        <Loader />
                    )
                    :
                    (
                        <SinglePageWrap  addTopMargin={!user}> 
                            <SinglePageLeft>
                                <ItemContainer>
                                    <ImageCarouselBtn 
                                        left={true}
                                        onClick={() => handleImageChange('left')}
                                            >
                                        <IoIosArrowBack />
                                    </ImageCarouselBtn>
                                    <img  
                                        src={imageSource} 
                                        alt='campaign' 
                                        style={{
                                            width: '100%', 
                                            height: '100%', 
                                            objectFit: 'cover', 
                                            borderTopLeftRadius: '15px', 
                                            borderTopRightRadius: '15px'
                                        }} 
                                        />
                                    <ImageCarouselBtn 
                                        right={true}
                                        onClick={() => handleImageChange('right')}
                                        >
                                        <IoIosArrowForward />
                                    </ImageCarouselBtn>
                                </ItemContainer>
        
                                <EventInfo> 
                                    <ChurchInfoWrap> 
                                        <ChurchInfo>
                                            <ChurchImage>
                                                <img 
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    src={`${imgUrl}/profile/${church.profileImg.imagePath2}`} 
                                                    alt='event' 
                                                    style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%'}} 
                                                    />
                                            </ChurchImage>
                                            <ChurchName>
                                                {church.fullName}
                                            </ChurchName>
                                        </ChurchInfo> 

                                        <ChurchInfo>
                                            <ViewOrganizerBtn
                                                onClick={() => {
                                                    if(!user){
                                                        window.open(`https://www.praysely.com/church/${church.username}`, '_blank')

                                                    }else{
                                                        window.open(`https://www.praysely.com/${church.username}`, '_blank')
                                                    }
                                                    }}
                                                >
                                                View Organizer
                                            </ViewOrganizerBtn>
                                        </ChurchInfo>
                                    </ChurchInfoWrap>
                                    <EventInfoWrap>
                                        <EventTitle>
                                            <span style={{width: "100%", display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                                                {singleCampaignData.title}
                                            </span>
                                            <AmountRaised>
                                                <p>Amount Raised</p> 
                                                <p style={{marginLeft: '10px'}}>${singleCampaignData.totalDonations }</p>
                                                <p style={{marginLeft: '5px', color: '#bbbbbb'}}>of ${singleCampaignData.goal.toLocaleString()} goal</p>
                                            </AmountRaised> 
                                            <StatusContainer>
                                                <RaisedColor
                                                    progress={
                                                        `${Number(singleCampaignData.totalDonations/singleCampaignData.goal).toFixed(2) * 100}%`
                                                    }    
                                                    >
                                                </RaisedColor>
                                            </StatusContainer> 
                                        </EventTitle>
                                        <EventDate> 
                                            { date && !isSingleEventLoading && ` 
                                                ${dateObj.dayLong[date.getDay()]} 
                                                ${dateObj.monthLong[date.getMonth()]} 
                                                ${date.getDate()} 
                                                ${date.getFullYear()}
                                            `} 
                                        </EventDate>
                                    </EventInfoWrap>
                                </EventInfo> 
                                { window.innerWidth < 768 && (
                                        <ActionBtnWrap>   
                                            {
                                                contentView === 'donate' ? (
                                                    <ActionButton
                                                        bgColor={'var(--themeColor)'}
                                                        txtColor={'white'}
                                                        onClick={() => {
                                                                scrollingBottom()
                                                                setContentView('donations')
                                                            }
                                                        }
                                                        >
                                                        <span >View Donations</span>
                                                    </ActionButton>
                                                )
                                                : contentView === 'donations' && 
                                                (
                                                    <ActionButton
                                                        bgColor={'var(--themeColor)'}
                                                        txtColor={'white'}
                                                        onClick={() => {  
                                                            scrollingBottom() 
                                                            setContentView('donate')
                                                        }}
                                                        >
                                                        <BiDonateHeart />
                                                        <span >Donate</span>
                                                    </ActionButton>
                                                ) 
                                            }  
                                        <ActionButton onClick={handleShare} >
                                            <GiLoveLetter />
                                            <span >Share</span>
                                        </ActionButton>  
                                    </ActionBtnWrap>
                                )}
                                <CampaignDescription> 
                                    { 
                                        singleCampaignData.description.split('\n').map((item, index) => {
                                            return <p key={index}>{item}</p>
                                        }) 
                                    } 
                                </CampaignDescription> 
                            </SinglePageLeft>
                            <SinglePageRight>
                                <TopRightItemContainer>  
                                    {
                                        contentView === 'donate' ? (
                                            <ActionButton
                                                bgColor={'var(--themeColor)'}
                                                txtColor={'white'}
                                                onClick={() => {
                                                        scrollingBottom()
                                                        setContentView('donations')
                                                    }
                                                }
                                                >
                                                <span >View Donations</span>
                                            </ActionButton>
                                        )
                                        : contentView === 'donations' && 
                                        (
                                            <ActionButton
                                                bgColor={'var(--themeColor)'}
                                                txtColor={'white'}
                                                onClick={() => {  
                                                    scrollingBottom() 
                                                    setContentView('donate')
                                                }}
                                                >
                                                <BiDonateHeart />
                                                <span >Donate</span>
                                            </ActionButton>
                                        )
                                    }  
                                    <ActionButton onClick={handleShare} >
                                        <GiLoveLetter />
                                        <span >Share</span>
                                    </ActionButton>  
                                </TopRightItemContainer>


                                <RightMainContent ref={ref}>  
                                {
                                    contentView === 'donate' ? (
                                        <Donate />
                                    )
                                    : contentView === 'donations' && (
                                        <Donations />
                                    )
                                } 
                                </RightMainContent>
                            </SinglePageRight>
                        </SinglePageWrap>
                    )
                )
            }

            <Modal
                open={viewShareModal}
                onClose={() => dispatch(setViewShareModal(false))}
                >
                <MUIModalWrap>   
                    <ShareCampaignModal />
                </MUIModalWrap>
            </Modal>  
        </SinglePageContainer>
    )
}

const ActionBtnWrap = styled.div` 
    display: flex;
    flex-direction: row;
    padding: 20px;
    width: 100%;
    justify-content: center;
    gap: 20px;
 
    /* if width is greater than 768 do not show */

    @media (min-width: 768px){
        display: none;
    }

`

const SinglePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    margin-top: 20px;
    height: fit-content;
    margin: 0px auto;

    @media (max-width: 1468px) {
        width: 80%;
    }

    @media (max-width: 1068px) {
        width: 100%;
    }

    @media (max-width: 968px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        height: 100vh;
        position: relative;
    }

`
const SinglePageWrap = styled.div`
    display: flex;
    height: calc(100vh - 100px);
    flex-direction: row;
    width: 100%;  
    margin-top: ${props => props.addTopMargin ? '20px' : '0px'};

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0px;
    }
`
const SinglePageLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: fit-content ;

    @media (max-width: 768px) {
        width: 100%;  
        padding: 10px; 
        height: fit-content;    
    }
`

const ImageCarouselBtn = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 30px;
    height: 80px;
    left: ${props => props.left ? '0px' : 'auto'};
    right: ${props => props.right ? '0px' : 'auto'};
    opacity: 0.4; 
    background-color: white; 
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 50px);
    cursor: pointer;
    transition: all 0.2s ease;
    
    
    @media (max-width: 768px) { 
    } 

    &:hover {
        opacity: 1;
    }

    svg{
        font-size: 25px;
    }

`

const CampaignTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 35px;
    color: #424242; 
    padding: 10px; 
    padding-left: 0px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 25px;
        padding-left: 20px;
        margin-bottom: 10px;
    }

`

const CampaignDescription = styled.div` 
    font-size: 15px;
    color: var(--lightThemeFontColor);
    font-weight: 500;
    padding: 40px 20px;
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 0 5px #ccc;
    text-align: left;

    @media (max-width: 768px) {
        margin-top: 0;
        padding: 20px;
        height: 600px;
        overflow-y: scroll;
    }

`

const TopRightItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start; 
    width: 100%; 
    height: fit-content; 
    padding: 0px;


    @media (max-width: 768px) {
        display: none;
        width: 100%; 
        z-index: 100;
        position: absolute;
        bottom: 0px;
        left: 0px;
        background-color: var(--themeColor);
        align-items: center;
        justify-content: center;
        padding: 11px;
    }

` 

const ActionButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 35%;
    height: 50px;
    border-radius: 10px;
    background-color: ${props => props.bgColor ? props.bgColor : 'white'};
    color: ${props => props.txtColor ? props.txtColor : 'var(--themeColor)'};
    box-shadow: 0px 0px 5px #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
    svg {
        font-size: 25px;
        margin-right: 10px;
    }
    &:hover {
        color: white;
        background-color: var(--themeColor);
    }
`

const StatusContainer = styled.div`
    width: 100%;
    display: flex;
    height: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #dbeaf6;
    border-radius: 10px;
    margin-top: 10px;
`
const RaisedColor = styled.div`
    width: ${props => props.progress};
    height: 10px;
    background-color: var(--themeColor);
    border-radius: 10px;

    transition: all 1s ease;

`

const AmountRaised = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%; 
    p {
        font-size: 15px;
        color: var(--lightThemeFontColor);
        font-weight: 500;
    } 
`

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    width: 100%;
    height: 60%;
    background-color: white;
    @media (max-width: 768px) {
        height: 100%;
    }
    position: relative;
`
const EventInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 10px;
    background-color: white;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    @media (max-width: 768px) {
        padding: 10px;
        height: fit-content;
    }
`
const EventInfoWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: fit-content;
    padding: 10px;

    @media (max-width: 768px) {
        padding: 0px;
    }

`
const ChurchInfoWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 10px;
    border-bottom: 1px solid #ececec;
    @media (max-width: 768px) {
        padding: 10px; 
    }
`

const ViewOrganizerBtn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 120px;
        height: 45px;
        font-size: 12px;
    border-radius: 25px;
    background-color: transparent;
    color: #4a4a4a;
    border: 1px solid #4a4a4a;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover { 
        box-shadow: 0 0 15px #ccc;
    }
 

`

const ChurchInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: fit-content; 
    @media (max-width: 768px) {
        padding: 10px; 
    }
`


const ChurchImage = styled.div`
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
`
const ChurchName = styled.div`
    font-size: 15px;
    color: var(--lightThemeSecondaryFontColor);
    font-weight: 500;
    padding: 10px;
    @media (max-width: 768px) {
        padding-left: 10px;
    }
`

const EventTitle = styled.div`
    font-size: 17px;
    color: var(--lightThemeFontColor);
    font-weight: 600;
    display: flex;
    flex-direction: column;
    width: 100%; 
    align-items: flex-start;
    @media (max-width: 768px) {
        margin-top: 10px;
        padding: 15px;
    }
`

const EventDate = styled.div`
    font-size: 12px;
    color: var(--lightThemeSecondaryFontColor);
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    @media (max-width: 768px) {
        padding: 5px;
    }
`

const SinglePageRight = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%; 
    width: 45%;
    padding: 0px 20px 10px 20px; 
    @media (max-width: 768px) { 
        padding: 5px;
        width: 100%;
    }
`



const RightMainContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content ; 
    margin-top: 20px; 
    @media (max-width: 768px) {
        margin-top: 10px;
        height: fit-content;
        padding: 5px;
    }
`