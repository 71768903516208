import styled from "styled-components"; 

export const AuthContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    font-family: "M PLUS Rounded 1c", serif;
    font-weight: 400;
    font-style: normal;
    color: #333;

    @media ( max-width: 768px){
        flex-direction: column;
    }

`
export const ImageBackground = styled.img`
    width: 100%;
`
export const ICTITLE = styled.div`
    font-size: 18px;
    @media ( max-width: 768px){
        background-size: 100%; 
        font-size: 14px; 
        margin-bottom: 10px;
    }
`



export const InfoContainer = styled.div`

    width: 80%;
    height: fit-content;
    opacity: .4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    background-color: black;
    color: white;
    font-size: 26px;
    padding: 40px;
    line-height: 1.9;
    border-radius: 22px;

    @media ( max-width: 768px){
        background-size: 100%;
        width: 90%;
        height: 80%;
        font-size: 24px;
        padding: 20px;
        line-height: 1.2; 
    } 
`

export const AuthSideInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;  
    background-color: #c8e8f7;
    background-image: url('https://api.praysely.com/prys/lp-church.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    @media ( max-width: 768px){
        background-size: 100%;
        width: 100%;
        height: 400px;
    }

`

export const AuthSideForm = styled.div`
    width: 30%;
    height: 100%; 

    @media ( max-width: 768px){
        width: 100%;
    }

`

export const FormContainer = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 35px;
    align-items: flex-start;
    justify-content: space-between;

`

export const LoginHeader = styled.div` 
    
    display: flex;
    flex-direction: column;
    width: 100%;  

`

export const FormMain = styled.div`
    width: 100%;
`

export const FormMainHeader = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;

    h1{
        font-size: 20px;  
    }

    p{
        font-size: 14px;
        color: gray;
    }

`

export const InputFieldItem = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;

    input{
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
 

`
export const AuthButton = styled.button`
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    margin-top: 20px;

    &:hover{
        background-color: #0056b3;
    }

`

export const LoginFooter = styled.div`
    font-size: 14px;
    color: gray;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

`