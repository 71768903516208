import React, { useEffect } from 'react'
import { checkConnectAccountStatus } from 'features/giving/givingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SettingsMainContainer  } from '../settings.styled'  
import VerifiedConnectAccount from './VerifiedConnectAccount'
import Oboarding from './Onboarding'
import RequirementsDue from './RequirementsDue'


export default function ChurchConnectAccount() {

    const dispatch = useDispatch() 
    const { 
        connectAccount,
        churchConnectAccountStatusLoading,
        churchConnectAccountStatusSuccess, 
    } = useSelector(state => state.giving)


    useEffect(() => {
        dispatch(checkConnectAccountStatus())
    }, [])


    if(churchConnectAccountStatusLoading){
        return (
            <SettingsMainContainer> 
                Verifying Praysely Giving Account Status...
            </SettingsMainContainer>
        )
    }else if(churchConnectAccountStatusSuccess && connectAccount && connectAccount.payouts_enabled === false){

        return (
            <RequirementsDue />
        )
    }else if(churchConnectAccountStatusSuccess && connectAccount && connectAccount.payouts_enabled){
        return (
            <VerifiedConnectAccount />
        )
    }else{
       return (
            <Oboarding />
        ) 
    }  
}
