import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import fileService from './fileService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {

    selectedFolder: {},
    selectedFile: null,
    sortFiles: localStorage.getItem('sortFiles') ? localStorage.getItem('sortFiles') : 'name',
    // sortFiles: 'name', 'date', 'fileType', 'dateModified',  

    widths: {
        title: 200,
        date: 150,
        type: 150,
    },

    fileUploadLoading: false,
    fileUploadError: false,
    fileUploadSuccess: false,

    isDataLoading: false,
    isDataLoadingError: false,
    isDataLoadingSuccess: false,

    fileToUpload: null,
    folderToAddFile: '',

    isCreateFolderLoading: false,
    isCreateFolderError: false,
    isCreateFolderSuccess: false,

    isFolderUpdating: false,
    isFolderUpdatingError: false,
    isFolderUpdatedSuccess: false,

    editNameLoading: false,
    editNameError: false,
    editNameSuccess: false,
    
    isFileDeleting: false, 
    isFileDeletingError: false,
    isFileDeletedSuccess: false,

    newFileName: {
        id: null,
        value: '',
    },

    viewNewFileModal: false,
    newFileType: null,

    viewAddFileModal: false, 
    viewMoveFileModal: false,
    viewCreateFolderModal: false,
    viewRenameFileModal: false,

    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null,
    hasNextPage: false,
    isDeleteFolderLoading: false,
    isDeleteFolderSuccess: false,
}

export const uploadFile = createAsyncThunk(
    'files/uploadFile',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.uploadFile(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const startCollection = createAsyncThunk(
    'files/startCollection',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.startCollection(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
) 

export const getFolders = createAsyncThunk(
    'files/getFolders',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.getFolders(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getCollectionFiles = createAsyncThunk(
    'files/getCollectionFiles',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.getCollectionFiles(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createFolder = createAsyncThunk(
    'files/createFolder',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.createFolder(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deleteFolder = createAsyncThunk(
    'files/deleteFolder',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.deleteFolder(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const renameFolder = createAsyncThunk(
    'files/renameFolder',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.renameFolder(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const renameFile = createAsyncThunk(
    'files/renameFile',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.renameFile(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const moveFile = createAsyncThunk(
    'files/moveFile',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.moveFile(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deleteFile = createAsyncThunk(
    'files/deleteFile',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await fileService.deleteFile(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        // @params: object { modal: string, view: boolean } 
        setViewFileModal: (state, action) => {
            state[action.payload.modal] = action.payload.view
        },

        setCollectionFilesFromLocalStorage: (state, action) => {
            state.collectionFiles = action.payload
        },

        setNewFileType: (state, action) => {
            state.newFileType = action.payload
        },
        setViewAddFileModal: (state, action) => {
            state.viewAddFileModal = action.payload
        },
        setViewCreateFolderModal: (state, action) => {
            state.viewCreateFolderModal = action.payload
        },
        setSelectedFileToUpload: (state, action) => {
            state.fileToUpload = action.payload
        },
        setFolderToAddFile: (state, action) => {
            state.folderToAddFile = action.payload
        },
        setSelectedFolder: (state, action) => {
            state.selectedFolder = action.payload
        },
        setSelectedFile: (state, action) => {
            state.selectedFile = action.payload
        },
        setWidths: (state, action) => {
            // keep the state immutable
            state.widths = action.payload
        },
        resetUploadState(state){
            state.folderToAddFile = ''
            state.fileUploadLoading = false
            state.fileUploadError = false
            state.fileUploadSuccess = false
            state.isCreateFolderLoading = false
            state.isCreateFolderError = false
            state.isCreateFolderSuccess = false
            state.editNameLoading = false
            state.editNameError = false
            state.editNameSuccess = false
            state.isFolderUpdating = false
            state.isFolderUpdatingError = false
            state.isFolderUpdatedSuccess = false
            state.isFileDeleting = false
            state.isFileDeletingError = false
            state.isFileDeletedSuccess = false
        }
    },
    extraReducers: (builder) => { 
        builder
            .addCase(getFolders.pending, (state, action) => {
                state.isDataLoading = true
                state.isDataLoadingError = false
                state.isDataLoadingSuccess = false
            })
            .addCase(getFolders.fulfilled, (state, action) => {
                state.isDataLoading = false
                state.isDataLoadingError = false
                state.isDataLoadingSuccess = true
                state.folders = action.payload.folders
            })
            .addCase(getFolders.rejected, (state, action) => {
                state.isDataLoading = false
                state.isDataLoadingError = true
                state.isDataLoadingSuccess = false
            })
            //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(getCollectionFiles.pending, (state, action) => {
                state.isDataLoading = true
                state.isDataLoadingError = false
                state.isDataLoadingSuccess = false
            })
            .addCase(getCollectionFiles.fulfilled, (state, action) => {
                state.isDataLoading = false
                state.isDataLoadingError = false
                state.isDataLoadingSuccess = true

                if(action.payload.message === 'hsm'){
                    console.log('hsm')
                }else{
                    state.collectionFiles = action.payload
                    localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(action.payload))                    
                }

            })
            .addCase(getCollectionFiles.rejected, (state, action) => {
                state.isDataLoading = false
                state.isDataLoadingError = true
                state.isDataLoadingSuccess = false
            })
            //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(createFolder.pending, (state, action) => {
                state.isCreateFolderLoading = true
                state.isCreateFolderError = false
                state.isCreateFolderSuccess = false
            })
            .addCase(createFolder.fulfilled, (state, action) => {
                state.isCreateFolderLoading = false
                state.isCreateFolderError = false
                state.isCreateFolderSuccess = true
                state.collectionFiles.folders.push(action.payload.folder)

                let ls = localStorage.getItem(action.payload.fileCollectionId.toString())
                let data = JSON.parse(ls)

                console.log(data)

                if(data.folders.length === 0){
                    data.folders = [action.payload.folder]
                }else{
                    data.folders.push(action.payload.folder)
                }
                data.hs = action.payload.hs
                data.lastUpdate = action.payload.lastUpdate
                localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))

 
            })
            .addCase(createFolder.rejected, (state, action) => {
                state.isCreateFolderLoading = false
                state.isCreateFolderError = true
                state.isCreateFolderSuccess = false
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(uploadFile.pending, (state, action) => {
                state.fileUploadLoading = true
                state.fileUploadError = false
                state.fileUploadSuccess = false
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.fileUploadLoading = false
                state.fileUploadError = false
                state.fileUploadSuccess = true

                let ls = localStorage.getItem(action.payload.fileCollectionId)
                let data = JSON.parse(ls)

                if(action.payload.fileFolderId){

                    state.collectionFiles.folders[action.payload.folderIndex].files.push(action.payload.file)
                    
                    if(state.selectedFolder){
                        if(state.selectedFolder._id === action.payload.fileFolderId){
                            state.selectedFolder.files.push(action.payload.file)
                        }
                    }

                    data.folders = state.collectionFiles.folders
                    data.hs = action.payload.hs
                    data.lastUpdate = action.payload.lastUpdate
                    localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))

                }else{
                    state.collectionFiles.files.push(action.payload.file)

                    data.files = state.collectionFiles.files
                    data.hs = action.payload.hs
                    data.lastUpdate = action.payload.lastUpdate
                    localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))
                }


            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.fileUploadLoading = false
                state.fileUploadError = true
                state.fileUploadSuccess = false
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(renameFolder.pending, (state, action) => {
                state.editNameLoading = true
                state.editNameError = false
                state.editNameSuccess = false
            })
            .addCase(renameFolder.fulfilled, (state, action) => {
                state.editNameLoading = false
                state.editNameError = false
                state.editNameSuccess = true

                if(state.selectedFolder){
                    state.selectedFolder.title = action.payload.newFolderTitle
                }

                state.collectionFiles.folders[action.payload.folderIndex].title = action.payload.newFolderTitle

                let ls = localStorage.getItem(action.payload.fileCollectionId)
                let data = JSON.parse(ls)

                data.folders[action.payload.folderIndex].title = action.payload.newFolderTitle
                data.hs = action.payload.hs
                data.lastUpdate = action.payload.lastUpdate
                localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))



            })
            .addCase(renameFolder.rejected, (state, action) => {
                state.editNameLoading = false
                state.editNameError = true
                state.editNameSuccess = false
            })

            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(moveFile.pending, (state, action) => {
                state.isFolderUpdating = true
            })
            .addCase(moveFile.fulfilled, (state, action) => {
                let ls = localStorage.getItem(action.payload.fileCollectionId)
                let data = JSON.parse(ls)

                let toFolderIndex = state.collectionFiles.folders.findIndex(folder => folder._id === action.payload.toFolderId)

                if(action.payload.fromFolderId){

                    let fromIndex = state.collectionFiles.folders.findIndex(folder => folder._id === action.payload.fromFolderId)

                    // remove file from the old folder
                    state.collectionFiles.folders[fromIndex].files = state.collectionFiles.folders[fromIndex].files.filter(file => file._id !== action.payload.fileToMoveId)
                    
                   // also remove from selected folder.. So the user can see the change in realtime. 
                    if(state.selectedFolder){
                        state.selectedFolder.files = state.selectedFolder.files.filter(file => file._id !== action.payload.fileToMoveId)
                    }

                    // add file to the new folder
                    state.collectionFiles.folders[toFolderIndex].files.push(action.payload.movedFile)


                }else{
                    // if it wasnt in a folder, it was likely in the main collection
                    // if so, remove it from the main collection
                    state.collectionFiles.files = state.collectionFiles.files.filter(file => file._id !== action.payload.fileToMoveId)


                    //  find index of new folder, and push the file into the new folder
                    state.collectionFiles.folders[toFolderIndex].files.push(action.payload.movedFile)

                }

                data.folders = state.collectionFiles.folders
                data.files = state.collectionFiles.files
                data.hs = action.payload.hs
                data.lastUpdate = action.payload.lastUpdate
                localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))


                state.isFolderUpdating = false
                state.isFolderUpdatingError = false
                state.isFolderUpdatedSuccess = true
            
            })
            .addCase(moveFile.rejected, (state, action) => {
                state.isFolderUpdating = false
                state.isFolderUpdatingError = true
                state.isFolderUpdatedSuccess = false
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(renameFile.pending, (state, action) => {
                state.editNameLoading = true
            })
            .addCase(renameFile.fulfilled, (state, action) => {
                let ls = localStorage.getItem(action.payload.fileCollectionId)
                let data = JSON.parse(ls)

                if(action.payload.folderId){

                    let folderIndex = state.collectionFiles.folders.findIndex(folder => folder._id === action.payload.folderId)
                    let fileIndex = state.collectionFiles.folders[folderIndex].files.findIndex(file => file._id === action.payload.file._id)

                    state.collectionFiles.folders[folderIndex].files[fileIndex].title = action.payload.newTitle
                    
                    if(state.selectedFile){
                        state.selectedFile.title = action.payload.newTitle
                    }

                    data.folders[folderIndex].files[fileIndex].title = action.payload.newTitle
                    data.hs = action.payload.hs
                    data.lastUpdate = action.payload.lastUpdate
                    localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))

                }else{

                    let fileIndex = state.collectionFiles.files.findIndex(file => file._id === action.payload.file._id)
                    state.collectionFiles.files[fileIndex].title = action.payload.newTitle

                    data.files[fileIndex].title = action.payload.newTitle
                    data.hs = action.payload.hs
                    data.lastUpdate = action.payload.lastUpdate
                    localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))

                }

                if(state.selectedFile){
                    state.selectedFile.title = action.payload.newTitle
                }

                state.newFileName.id = action.payload.file._id
                state.newFileName.value = action.payload.newTitle

                state.editNameLoading = false
                state.editNameError = false
                state.editNameSuccess = true
            })
            .addCase(renameFile.rejected, (state, action) => {
                state.editNameLoading = false
                state.editNameError = true
                state.editNameSuccess = false
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(deleteFile.pending, (state, action) => {
                state.isFileDeleting = true
            })
            .addCase(deleteFile.fulfilled, (state, action) => {
                let ls = localStorage.getItem(action.payload.fileCollectionId)
                let data = JSON.parse(ls)

                if(action.payload.folderId){
                    let folderIndex = state.collectionFiles.folders.findIndex(folder => folder._id === action.payload.folderId)
                    state.collectionFiles.folders[folderIndex].files = state.collectionFiles.folders[folderIndex].files.filter(file => file._id !== action.payload.fileId)

                    if(state.selectedFolder){
                        if(state.selectedFolder._id === action.payload.folderId){
                            state.selectedFolder.files = state.selectedFolder.files.filter(file => file._id !== action.payload.fileId)
                        }
                    }

                    data.folders[folderIndex].files = state.collectionFiles.folders[folderIndex].files
                    data.hs = action.payload.hs
                    data.lastUpdate = action.payload.lastUpdate
                    localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))

                }else{
                    state.collectionFiles.files = state.collectionFiles.files.filter(file => file._id !== action.payload.fileId)
                    data.files = state.collectionFiles.files
                    data.hs = action.payload.hs
                    data.lastUpdate = action.payload.lastUpdate
                    localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))

                }
                state.isFileDeleting = false
                state.isFileDeletingError = false
                state.isFileDeletedSuccess = true
            })
            .addCase(deleteFile.rejected, (state, action) => {
                state.isFileDeleting = false
                state.isFileDeletingError = true
                state.isFileDeletedSuccess = false
            })
            // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
            .addCase(deleteFolder.pending, (state, action) => {
                state.isDeleteFolderLoading = true
            })
            .addCase(deleteFolder.fulfilled, (state, action) => {
                state.selectedFolder = null
              
                let ls = localStorage.getItem(action.payload.fileCollectionId)
                let data = JSON.parse(ls)

                state.collectionFiles.folders = state.collectionFiles.folders.filter(folder => folder._id !== action.payload.folderId)

                if(state.selectedFolder){
                    if(state.selectedFolder._id === action.payload.folderId){
                        state.selectedFolder = null
                    }
                }

                data.folders = state.collectionFiles.folders
                data.hs = action.payload.hs
                data.lastUpdate = action.payload.lastUpdate
                localStorage.setItem(action.payload.fileCollectionId, JSON.stringify(data))
 
                state.isFolderUpdatingError = false 
                state.isDeleteFolderLoading = false
                state.isDeleteFolderSuccess = true
            })
            .addCase(deleteFolder.rejected, (state, action) => {
                state.isDeleteFolderLoading = false
                state.isDeleteFolderSuccess = false
            })


    }
})

export const { 
    setViewAddFileModal, 
    setViewCreateFolderModal,
    setSelectedFileToUpload,
    setFolderToAddFile,
    setSelectedFolder,
    resetUploadState,
    setWidths,
    setViewFileModal,
    setSelectedFile,
    setNewFileType,
    setCollectionFilesFromLocalStorage
 } = fileSlice.actions

export default fileSlice.reducer
