import React, { useEffect  } from 'react'
import styled from 'styled-components'  
import { useDispatch, useSelector } from 'react-redux';
import {  getDonationsByCampaign } from 'features/givingCampaigns/givingCampaignSlice'; 
 
import { useParams } from 'react-router-dom'
import { LiaDonateSolid } from "react-icons/lia"; 
import SpinnerIntermintentSmall from 'loaders/SpinnerIntermintentSmall';
import { DH1, DonateContainer } from '../giving.styled';

export default function Donations() {

    const dispatch = useDispatch()
    const { campaignId } = useParams()
 
    const { 
        getDonationsByCampaignLoading, 
        campaignDonations,
        getDonationsByCampaignSuccess
     } = useSelector((state) => state.givingCampaign)


    useEffect(() => { 
        dispatch(getDonationsByCampaign({ 
            campaignId: campaignId
        })) 
    }, [campaignId])


    return (
        <DonateContainer maxHeight={'500px'} >
            <DH1>
                {/* <LiaDonateSolid /> */}
                Donations
            </DH1>
            {
                getDonationsByCampaignLoading ? 
                (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center', 
                            color: 'var(--lightThemeFontColor)',
                            marginBottom: '20px',
                            marginTop: '20px'
                        }}
                        > 
                        <SpinnerIntermintentSmall color={'#ccc'} /> 
                    </div>
                )
                : getDonationsByCampaignSuccess && campaignDonations.length > 0 ?
                (
  
                    campaignDonations.map((donation, index) => (
                        <DonationItem key={index}>
                            <LiaDonateSolid color='#6b6b6b'/> 
                            <div
                                style={{
                                    width: '90%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                                >
                                <p>${donation.amount.toLocaleString()}</p>
                                <p>{donation.donorName}</p>                                        
                            </div>

                        </DonationItem>
                    )) 
                )
                : getDonationsByCampaignSuccess && campaignDonations.length === 0 &&
                (
                    <div>
                        <p
                            style={{
                                color: 'var(--lightThemeFontColor)',
                                textAlign: 'center',
                                marginTop: '20px'
                            }}
                            >
                            No donations yet</p>
                    </div>
                )
            }
        </DonateContainer>
    )
}

const DonationItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    padding-left: 0px;
    border-bottom: 1px solid #f1f1f1; 
    height: 55px;

    h3 {
        font-size: 16px;
        font-weight: 500;
        color: var(--lightThemeFontColor);
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: var(--lightThemeFontColor);
    }

`
